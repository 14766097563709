'use strict';

var ConnectedPage = {
  iconList: { OX: 'so2', WS: 'wt', BPC: 'bp', TEMP: 'temp', GC: 'bg', GC1: 'bg', SPIRO: 'lungs', LUNG: 'lungs', PEAK: 'lungs', COAGU: 'bg', ECG: 'heart' },
  sensors: [],
  runningTest: null,

  setup: function setup() {
    var self = this;
    document.addEventListener("resume", function () {
      self.onResume();
    }, false);
  },

  onResume: function onResume() {
    if (this.settingsOpened) {
      this.settingsOpened = false;
      this.refresh();
    }
  },

  refresh: function refresh() {
    if (this.runningTest != null) {
      this.resetTests();
    }

    this.init();
  },

  init: function init() {
    var self = this;
    this.makeSensorInfoList();

    BLE_CONTROL.enableDeferred().then(function () {
      return self.findPairedSensors();
    }).always(function () {
      self.updateDisplay();
    });
  },

  findPairedSensors: function findPairedSensors() {
    var deferred = $.Deferred();

    if (deviceOS == "iOS") {
      //iOS does not support medicalsensors.
      deferred.reject();
      return deferred.promise();
    }

    medicalSensors.list(function (result) {
      // make a table of paired devices
      console.log(result);
      var pairedDevices = {};
      if (result) {
        $.each(result, function (i, pairedDevice) {
          var btId = pairedDevice.address.replace(/\:/g, "").toUpperCase();
          pairedDevices[btId] = pairedDevice;
        });
      }

      // look up each device in the table
      $.each(ConnectedPage.sensors, function (index, sensorInfo) {
        if (!sensorInfo.isSupported) {
          if (!inBrowser) {
            sensorInfo.showPaired = true;
            sensorInfo.pairedText = $t("sensors.not_supported");
          }
        } else if (!sensorInfo.needsPairing) {
          sensorInfo.paired = true;
          sensorInfo.showPaired = false;
          sensorInfo.showPairInstructions = false;
          sensorInfo.showTestButton = true;
          sensorInfo.pairedText = "";
        } else {
          sensorInfo.pairingInfo = pairedDevices[sensorInfo.bluetoothId.replace(/\:/g, "").toUpperCase()];
          sensorInfo.paired = !!sensorInfo.pairingInfo;
          sensorInfo.showPaired = true;
          if (sensorInfo.paired) {
            sensorInfo.showPairInstructions = false;
            sensorInfo.showTestButton = true;
            sensorInfo.pairedText = $t("sensors.paired");
          } else {
            sensorInfo.showPairInstructions = true;
          }
        }
      });

      deferred.resolve();
    }, function () {
      deferred.reject();
    });

    return deferred.promise();
  },

  makeSensorInfoList: function makeSensorInfoList() {
    var self = this;

    ConnectedPage.sensors = [];

    if (Profile.kit && $.type(Profile.kit.sensors) === "array") {
      $.each(Profile.kit.sensors, function (index, sensor) {
        var restricted = Profile.hasSensorTypeRestriction(sensor['sensor-type-name']);
        if (!restricted) {
          ConnectedPage.sensors.push(self.makeSensorInfo(index, sensor));
        }
      });
    }
  },

  makeSensorInfo: function makeSensorInfo(index, sensor) {
    var sensorInfo = { index: index };
    sensorInfo.needsPairing = sensor.device && sensor.device.needsPairing();
    sensorInfo.isSupported = sensor.device && sensor.device.isSupported();
    sensorInfo.showPairInstructions = false;
    sensorInfo.showTestButton = false;
    sensorInfo.showPaired = false;
    sensorInfo.id = sensor['sensor-type-identification'];
    sensorInfo.sensorType = sensor['sensor-type-name'];
    sensorInfo.sensorTypeLabel = sensor['sensor-type-label'];
    sensorInfo.bluetoothId = sensor['identification'].toUpperCase();
    sensorInfo.serialNumber = sensor['sensor-serial'];
    sensorInfo.manufacturerName = sensor['sensor-manufacturer-name'];
    var pairInstructions = $t("sensors.pairing_instructions." + sensor["sensor-type-identification"]);
    if (pairInstructions.indexOf("sensors.pairing_instructions.") === 0) {
      pairInstructions = "";
    }
    sensorInfo.pairInstructions = pairInstructions;
    sensorInfo.modelName = sensor['sensor-model-name'];
    sensorInfo.pairedText = $t("sensors.not_paired");
    sensorInfo.icon = ConnectedPage.iconList[sensorInfo.sensorType] ? ConnectedPage.iconList[sensorInfo.sensorType] : 'device';

    sensorInfo.units = sensor.device == null ? [] : sensor.device.settableUnits();
    sensorInfo.unit_label = $t("sensors.units_label");
    sensorInfo.showUnits = sensorInfo.units.length > 0;

    if (deviceOS == "iOS" && sensor.device) {
      sensorInfo.showTestButton = true;
    }

    return sensorInfo;
  },

  updateDisplay: function updateDisplay() {
    $('#sensor-list').empty();
    if (ConnectedPage.sensors.length == 0) {
      $('#sensor-list').append($("<li >" + $t("sensors.no_sensors") + "</li>")).trigger("create");
    } else {
      if (inBrowser) {
        $('#sensor-list').append($("<li><span class='secondary-text-color'>" + $t("sensors.in_browser") + "</span></li>")).trigger("create");
      }
      $.each(ConnectedPage.sensors, function (index, sensor) {
        var device_detail = applyI18nAttrs(ich.t_deviceItem(sensor));
        $('#sensor-list').append($(device_detail)).trigger("create");
      });
    }

    $('#sensor-list').listview("refresh");
    $("select.unit-input").selectmenu('refresh');
  },

  changeUnits: function changeUnits(elem) {
    setTimeout(function () {
      var parent = $(elem).closest(".unit-change-section");
      var selectDiv = $(".unit-select", parent[0]);
      var select = $("select.unit-input", parent[0]);
      select.val("0").selectmenu('refresh');
      selectDiv.show();
      $(elem).hide();
    }, 400); // let ripple finish
  },

  closeChangeUnits: function closeChangeUnits(elem) {
    setTimeout(function () {
      var parent = $(elem).closest(".unit-change-section");
      var selectDiv = $(".unit-select", parent[0]);
      var button = $(".sensor-units-button", parent[0]);
      var select = $("select.unit-input", parent[0]);
      select.val("0").selectmenu('refresh');
      selectDiv.hide();
      button.show();
    }, 400); // let ripple finish
  },

  testSensor: function testSensor(elem) {
    var self = this;
    var index = parseInt($(elem).data("sensor-index"));

    if (this.runningTest == null) {
      this.resetTests();

      BLE_CONTROL.enableDeferred().done(function () {
        self.startTest(index, elem);
      }).fail(function () {
        openWarningPopup($t("errors.bluetooth_must_be_enabled"));
      });
    } else if (this.runningTest == index) {
      this.resetTests();
    } else {
      openWarningPopup($t("sensors.test_already_running"));
    }
  },

  onBack: function onBack() {
    this.resetTests();
  },

  resetTests: function resetTests() {

    if (this.runningTest != null) {
      var sensor = Profile.kit.sensors[this.runningTest];
    }

    BLE_CONTROL.stopAllReadings();
    $(".sensor-test-button").text($t("buttons.test_sensor"));
    this.resetTestProgress();
    this.runningTest = null;
  },

  resetTestProgress: function resetTestProgress() {
    $(".sensor-test-instructions").html("");
    $(".sensor-test-progress").text("");
  },

  openSettings: function openSettings(elem) {
    this.settingsOpened = true;
    BLE_CONTROL.showSettings();
  },

  startTest: function startTest(index, elem) {
    var self = this;

    this.runningTest = index;
    $(elem).text($t("buttons.stop_sensor_test"));

    var sensor = Profile.kit.sensors[index];

    // show instructions
    var readingInstructions = $t("sensors.reading_instructions." + sensor["sensor-type-identification"]);
    if (readingInstructions.indexOf("sensors.reading_instructions.") === 0) {
      readingInstructions = "";
    }
    var readingInstructionsElem = $(".sensor-test-instructions[data-sensor-index=" + index + "]");
    readingInstructionsElem.html(readingInstructions);

    // set up progress
    var progressElem = $(".sensor-test-progress[data-sensor-index=" + index + "]");
    progressElem.text($t("sensors.connect_progress_waiting"));

    // get connect parameters
    var connectParams = { scanWaitTime: 10 * 60 * 1000
      // get unit settings if any
    };var parent = $(elem).closest("li.sensor-settings-section");
    var unitElem = $("select.unit-input", parent[0]);
    var unitVal = unitElem.val();
    console.log("unit choice:" + unitVal);
    if (unitVal && unitVal != "0") {
      connectParams.units = unitVal;
    }
    // read from sensor

    var progress = function progress(progressText) {
      if ($.type(progressText) === "string") {
        progressText = { message: progressText };
      }

      progressElem.text(progressText.message);
    };

    sensor.device.waitForReadings(connectParams, progress).then(function (readings) {
      var result = self.handleReadings(readings);

      progressElem.text($t("sensors.connect_progress_" + result.status));
      self.displayResults(index, result);

      $(".sensor-test-button").text($t("buttons.test_sensor"));
      self.runningTest = null;
      BLE_CONTROL.stopAllReadings();
    }, function (error) {
      console.error(error);

      var message = $t("sensors.connect_progress_error");
      if (error == "timeout") {
        message = $t("sensors.connect_progress_timeout");
      }
      if (error.unitsSet) {
        message = $t("sensors.units_changed");
      }

      progressElem.text(message);
      readingInstructionsElem.html("");

      $(".sensor-test-button").text($t("buttons.test_sensor"));
      self.runningTest = null;
      BLE_CONTROL.stopAllReadings();
    });
  },

  handleReadings: function handleReadings(result) {
    console.log(result);
    var measList = [];
    var status = "";
    var mainReading = this.getMainReading(result.readings);

    if (mainReading == null || mainReading.measurements.length == 0) {
      status = "no_readings";
    } else {
      status = "test_complete";
      measList = mainReading.measurements;
    }
    return { status: status, measurements: measList };
  },

  getMainReading: function getMainReading(readingArray) {
    if (readingArray && readingArray.length > 0) {
      for (var i = 0; i < readingArray.length; i++) {
        if (readingArray[i].main) {
          return readingArray[i];
        }
      }
      return readingArray[0];
    }

    return null;
  },

  displayResults: function displayResults(index, result) {
    var measLookup = Interviews.getAllMeasurementDefinitions();
    var readingInstructionsElem = $(".sensor-test-instructions[data-sensor-index=" + index + "]");
    readingInstructionsElem.html("");
    var txtResults = [];

    $.each(result.measurements, function (i, measurement) {
      var name = measurement.name;
      if (name != "map" && name != "zwt") {
        var measDef = measLookup[name];
        if (measDef) {
          name = measDef.text.replace(/\(.*\)$/, ""); // strip out units from TM
        }
        // strip leading 0's
        var val = measurement.value.replace(/^0*/, "");
        if (val.startsWith(".")) {
          val = "0" + val;
        }

        var txt = name + ": " + val + " " + measurement.unit; // show units from device
        txtResults.push(txt);
      }
    });

    if (txtResults.length > 0) {
      var msg = $t("sensors.readings_header") + "<BR/>" + txtResults.join("<BR/>");
      readingInstructionsElem.html(msg);
    }
  }

};