"use strict";

/* exported Omron708BT */
var Omron708BT = new JS.Class(Bluetooth_Device, {
  initialize: function initialize(device_uuid) {

    var name = "Omron 708-BT Blood Pressure Cuff";
    var mymedic_id = "HdpOmronBPBTType";

    return this.callSuper(name, device_uuid, mymedic_id, 1);
  }

});