'use strict';

var IdleHandler = {
  idle: false,
  idleTimer: null,
  counter: null,
  lastActivity: new Date().getTime(),
  countDownInterval: null,
  inactiveTime: -1,
  countDownTime: 1,
  oneMinuteMessage: 'Session will expire in one minute',
  moreMinutesMessage: 'Session will expire in {count} minutes',

  init: function init() {
    this.inactiveTime = mymobileConfig.user_timeout_in_minutes < 0 ? -1 : mymobileConfig.user_timeout_in_minutes * 60 * 1000;
    this.countDownTime = mymobileConfig.user_timeout_countdown_minutes;
    this.oneMinuteMessage = $t('messages.inactivity.1_minute');
    this.moreMinutesMessage = $t('messages.inactivity.n_minutes');
  },

  handleUserEvent: function handleUserEvent() {
    this.lastActivity = new Date().getTime();
    if (this.idle) {

      Auth.ping(); // if we got into the idle timeout and it was cancelled due to activity, ping the server to keep the session alive
      this.idleEnd();
    }
  },

  start: function start() {
    if (this.inactiveTime > 0) {
      this.resetIdleTimer();
    } else {
      this.stop();
    }
  },

  stop: function stop() {
    if (this.idleTimer) {
      clearTimeout(this.idleTimer);
      this.idleTimer = null;
    }
  },

  resetIdleTimer: function resetIdleTimer() {
    if (!Auth.loggedIn()) {
      IdleHandler.handleUserEvent();
    }

    clearTimeout(this.idleTimer);

    var timeSinceLastActivity = new Date().getTime() - this.lastActivity;
    var projectedTimeOut = this.inactiveTime - timeSinceLastActivity;

    if (projectedTimeOut <= 0) {
      if (!this.idle) {
        this.idleStart();
      }
      projectedTimeOut = this.inactiveTime;
    }

    this.idleTimer = setTimeout("IdleHandler.resetIdleTimer()", projectedTimeOut);
  },

  idleMessage: function idleMessage() {
    var message;
    if (this.idleCounter == 1) {
      message = this.oneMinuteMessage;
    } else {
      message = this.moreMinutesMessage.replace('{count}', this.idleCounter);
    }
    openWarningPopup(message);
  },

  idleStart: function idleStart() {
    this.idle = true;

    if (this.countDownInterval == null) {
      this.idleCounter = this.countDownTime < 1 ? 5 : this.countDownTime;
      this.idleMessage();
      this.countDownInterval = setInterval("IdleHandler.idleCountdown()", this.countDownTime < 1 ? 1000 : 1000 * 60);
    }
  },

  idleEnd: function idleEnd() {
    this.idle = false;

    if (this.countDownInterval != null) {
      clearInterval(this.countDownInterval);
      this.countDownInterval = null;
      this.idleCounter = null;
      closeWarningPopup();
    }
  },

  idleCountdown: function idleCountdown() {
    this.idleCounter -= 1;

    if (this.idleCounter <= 0) {
      this.logOut();
    } else {
      this.idleMessage();
    }
  },

  logOut: function logOut() {
    this.idleEnd();
    Interviews.timedOut();
    MainPage.logOut('timeout');
  },

  checkIfPastIdleTimeout: function checkIfPastIdleTimeout() {
    if (!Auth.loggedIn()) {
      return;
    }

    if (this.inactiveTime <= 0) {
      return;
    }

    var currentTime = new Date().getTime();

    if (currentTime >= this.lastActivity + this.inactiveTime) {
      //Logged in, inactivetime is positive, timeout has passed, No rememberme.
      this.logOut();
    }
  }

};