"use strict";

var _ref;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* exported XMLBuilder */
/* globals marknote */
var XMLBuilder = new JS.Class((_ref = {
  doc: null,
  root: null,

  initialize: function initialize() {
    this.doc = new marknote.Document();
  },

  toString: function toString() {
    return this.doc.toString();
  }

}, _defineProperty(_ref, "root", function root(name, attrs) {
  this.root = this.element(name, attrs);
  this.doc.setRootElement(this.root);
  return this;
}), _defineProperty(_ref, "add", function add(nameOrElem, optionalText) {
  if (arguments.length == 1) this.root.addChildElement(nameOrElem);else this.add(this.text(nameOrElem, optionalText));
  return this;
}), _defineProperty(_ref, "addWithAttrs", function addWithAttrs(name, optionalText, attrs) {
  var element = new marknote.Element(name);
  if (attrs) {
    for (var attr in attrs) {
      element.setAttribute(attr, attrs[attr]);
    }
  }
  if (optionalText) {
    element.setText(optionalText);
  }
  this.add(element);
  return this;
}), _defineProperty(_ref, "addTo", function addTo(parent, child) {
  parent.addChildElement(child);
}), _defineProperty(_ref, "element", function element(name, attrs) {
  var element = new marknote.Element(name);
  if (attrs) {
    for (var attr in attrs) {
      element.setAttribute(attr, attrs[attr]);
    }
  }
  return element;
}), _defineProperty(_ref, "text", function text(name, _text, attrs) {
  var element = this.element(name, attrs);
  element.setText(_text);
  return element;
}), _defineProperty(_ref, "container", function container(name, children, attrs) {
  var element = this.element(name, attrs);
  if (children) {
    for (var i = 0; i < children.length; i++) {
      element.addChildElement(children[i]);
    }
  }
  return element;
}), _defineProperty(_ref, "date", function date(_date) {
  if (_date) return moment(_date).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";else return "";
}), _ref));