"use strict";

/* exported AND_UC_355PBT_Ci */
var AND_UC_355PBT_Ci = new JS.Class(Bluetooth_Device, {
  initialize: function initialize(device_uuid) {

    var name = "A&D UC-355-PBT-Ci Weight Scale";
    var mymedic_id = "ADBTWS355CIType";

    return this.callSuper(name, device_uuid, mymedic_id, 1);
  }

});