var ByteArrayConverter = {
  hexChar: ["0", "1", "2", "3", "4", "5", "6", "7","8", "9", "A", "B", "C", "D", "E", "F"],
  byteToHex: function(byte) { return this.hexChar[(byte >> 4) & 0x0f] + this.hexChar[byte & 0x0f]; },
  byteArrayToHexString: function(arrayBuffer) { 
    var byteString = "";

    var byteArray = new Uint8Array(arrayBuffer);

    for (var i = 0; i < byteArray.length;i++) {
        byteString += this.byteToHex(byteArray[i]);
    }
    return byteString.trim(); 
  },
  byteArrayToBase64: function(byteArray) {
    return btoa([].reduce.call(new Uint8Array(byteArray),function(p,c){return p+String.fromCharCode(c)},''))
  }
}