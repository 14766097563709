
var kanayo;

  if( typeof kanayo === "undefined" ){ kanayo = {} }
  if( !kanayo.util ){ kanayo.util = {} }




kanayo.util.summary = function(/*string*/str, /*number*/len){
//  Truncates 'str' after 'len' characters and appends periods as necessary so that it ends with "..."

  if(!len || str.length <= len){
    return str; // string
  }

  return str.substring(0, len).replace(/\.+$/, "") + "..."; // string
}

/*
    Clear form fields - so far just text and textarea, may need form-specific
    clear code in future.
*/
kanayo.util.clearForm = function( formName )
{
    if( formName && document[formName] )
    {
        for( var i = 0; i < document[formName].elements.length; i++)
        {
             switch( document[formName].elements[i].type )
             {
                case "text":
                case "textarea":
                {
                    document[formName].elements[i].value = "";
                    break;
                }
             }
        }
    }
}

/*
    This functions is used to execute the script block(s) in a html node.
    This is especially useful if new data is inserted into the page via an ajax call
    that contains script.
*/
kanayo.util.executeScript = function( nodeId )
{
    var element = document.getElementById( nodeId );
    var scripts = element.getElementsByTagName( "script" );

    for(var i = 0; i < scripts.length; i++)
    {
      eval(scripts[i].text);
    }
}


/*
    Return an array of all child elements with the named attribute, excluding
    the startElement.
*/

kanayo.util.getElementsByAttributeName = function (startElement, attributeName )
{
    var allElements =  startElement.getElementsByTagName("*");
    var foundElements = [];

    for( var i = 0; i < allElements.length; i++ )
    {
        var element = allElements[i];
        var attribute = element.getAttribute(attributeName);

        if( typeof(attribute) === 'string' && attribute.length > 0 )
        {
            foundElements.push(  element )
        }
    }

    return foundElements;
}



kanayo.util.getChildById = function (startElement, id)
{
    var allElements =  startElement.getElementsByTagName("*");

    for(var i = 0; i < allElements.length; i++ )
    {
        if(allElements[i].id == id)
        {
            return allElements[i];
        }
    }

    return null;
}

kanayo.util.arrayMax = function (arr)
{
    var max = null;
    if(arr.length > 0)
    {
        max = arr[0];
    }

    for( var i = 1; i < arr.length; i++ )
    {
        max = Math.max(max, arr[i]);
    }

    return max;
}

/*
    Get Selected value in a select list.
*/

kanayo.util.getSelectedOption = function ( selectElement )
{
    return selectElement.options[ selectElement.selectedIndex ]
}

/*
    Select a value in a select list.
*/

kanayo.util.selectOptionByValue = function (selectElement, value )
{
    for( var i = 0; i < selectElement.length; i++ )
    {
        if( value == selectElement.options[i].value )
        {
            selectElement.options[i].selected = true
        }
        else
        {
            selectElement.options[i].selected = false
        }
    }
}

/*
    remove options from a select list with the given values.
*/

kanayo.util.removeOptionsByValue = function (selectElement, list )
{
    if( !list ) list = [];

    for( var j = 0; j < list.length; ++j)
    {
        for( var i = 0; i < selectElement.length; i++ )
        {
            if( list[j] == selectElement.options[i].value )
            {
                selectElement.remove(i);
                break;
            }
        }
    }
}

kanayo.util.insertOption = function( selectElement, beforeIndex, value, label )
{
    var opt = document.createElement('option');
    opt.text = label
    opt.value = value

    try
    {
        // standards compliant
        if( beforeIndex == null )
            selectElement.add( opt , null );
        else
            selectElement.add( opt , selectElement.options[beforeIndex] );

    }
    catch(ex)
    {
         // IE only
        if( beforeIndex == null )
            selectElement.add( opt );
        else
            selectElement.add( opt, beforeIndex );
    }

    return opt;
}


kanayo.util.insertOptionSorted = function( selectElement, value, label )
{
    var inserted = false;
    var opt = null
    var upperLabel = label.toUpperCase();

    for( var i = 0; i < selectElement.length && !inserted; i++ )
    {
        if( upperLabel < selectElement.options[i].text.toUpperCase() )
        {
            opt = kanayo.util.insertOption( selectElement, i, value, label )
            inserted = true;
        }
    }

    if( !inserted )
    {
        opt = kanayo.util.insertOption( selectElement, null, value, label )
    }

    return opt
}


/*
    After cloning a node you sometimes want to update ids and
    other attrs to be unique. This method replaces all instances
    of 'oldCounter' with 'newCounter' in the attributes named
    in the list 'checkAttrNames' for the node tree starting at 'startElement'.

    Additionally nodes with a 'clearValue'        attr will have .value = ""
    and          nodes with a 'setValueToCounter' attr will have .value = newCounter
*/

kanayo.util.fixClonedAttributes = function ( checkAttrNames, startElement, oldCounter, newCounter )
{
    var allHtmlElements =  startElement.getElementsByTagName("*");
    var allElements = []
    for( var i = 0; i < allHtmlElements.length; i++ )
    {
      allElements.push( allHtmlElements[i] )
    }
    allElements.push(startElement)
    
    var counterExp = new RegExp( String( oldCounter ) )
    var replStr = String( newCounter )

    for( var i = 0; i < allElements.length; i++ )
    {
      var element = allElements[i];
       
        for( var j = 0; j < checkAttrNames.length; j++ )
        {
            var attrName = checkAttrNames[j]
            var attrValue = element.getAttribute(attrName);

            if( attrValue )
            {
                element.setAttribute( attrName, attrValue.replace(counterExp, replStr ) )
            }
        }

        if( element.getAttribute('setValueToCounter') )
        {
            element.value = newCounter
        }
        else if( element.getAttribute('clearValue') )
        {
            element.value = ""
        }
      
    }
}

/*
  Turn an array into an array of rows given the number of rows
  Pads each row with nulls
*/

kanayo.util.columnize = function( list, num_rows)
{

    var rows = []
    var num_per_row = Math.floor( list.length / num_rows )

    if( list.length % num_rows > 0 ) num_per_row++

    for( var i = 0; i < num_rows; ++i )
    {
        var endSlice = i * num_per_row + num_per_row;
        var extra = 0;

        if( endSlice > list.length )
        {
            extra = endSlice - list.length
            endSlice = list.length;
        }

        var row = list.slice( i * num_per_row, endSlice )

        if( extra > 0 )
        {
            var extraArray = []

            for( var j = 0; j < extra; j++ )
            {
                extraArray[j] = null
            }

            row =  row.concat( extraArray )
        }

        rows.push(  row  )
    }

    return rows
}


/*
   reverse columns and rows
*/

kanayo.util.transpose = function( list )
{
    var newList = []

    for( var rowIndex = 0; rowIndex < list.length; ++rowIndex )
    {
        for( var colIndex = 0; colIndex < list[rowIndex].length; ++colIndex )
        {
            if( !newList[colIndex] ) newList[colIndex] = []

            newList[colIndex][rowIndex] = list[rowIndex][ colIndex ]
         }
    }

    return newList
}


kanayo.util.getInputsByType = function(startElement, type)
{
    var input_elems = startElement.getElementsByTagName("input");
    var elems = [];

    for( var i = 0; i < input_elems.length; ++i )
    {
        var element = input_elems[i];
        if( element.type.toUpperCase() == type.toUpperCase() )
        {
            elems.push(element);
        }
    }

    return elems;
}

kanayo.util.trim = String.prototype.trim ?
  function(str){ return str.trim(); } :
  function(str){ return str.replace(/^\s\s*/, '').replace(/\s\s*$/, ''); };



kanayo.util.filterTime = function(text)
{
  var allowed_separator = ':';
  text = text.replace(";", allowed_separator);
  text = text.replace(".", allowed_separator);
  text = text.replace(",", allowed_separator);

  text = kanayo.util.trim( text )
  var lastDotIndex = text.lastIndexOf( allowed_separator )

  if( lastDotIndex < 0  )
  {
      text = text.replace(/\D/g, "");
      if( text.length == 1)      { text = '0' + text + allowed_separator + '00' }
      else if( text.length == 2) { text = text + allowed_separator + '00' }
      else if( text.length == 3) { text = '0' + text.substr(0,1) + allowed_separator + text.substr(1,2) }
      else if( text.length >= 4) { text = text.substr(0,2) + allowed_separator + text.substr(2,2) }
  }
  else
  {
      var left = text.substring(0,lastDotIndex).replace(/\D/g, "")
      var right = text.substring(lastDotIndex).replace(/\D/g, "")
      if( left.length == 0)      { left = '00' }
      else if( left.length == 1) { left = '0' + left }
      else if( left.length > 2)  { left = left.substr(0,2) }

      if( right.length == 0)     { right = '00' }
      else if( right.length == 1) { right = '0' + right }
      else if( right.length > 2)  { right = right.substr(0,2) }

      text = left + allowed_separator + right
  }

  return text;
}


kanayo.util.unescapeXml = function(/*string*/str, /*boolean*/noSingleQuotes){
//summary:
//  Replace escape sequences for special characters in XML: &<>"'
//  Optionally skips escapes for single quotes

  if(!noSingleQuotes){ str = str.replace(/&#39;/gm, "'"); }
  str = str.replace(/&quot;/gm, '"').replace(/&lt;/gm, "<")
    .replace(/&gt;/gm, ">").replace(/&amp;/gm, "&");
  return str; // string
}

kanayo.util.replaceDiacritics = function(/*string*/str)
{
  var s = str;

  var diacritics =[
    /[\300-\306]/g, /[\340-\346]/g, // A, a
    /[\310-\313]/g, /[\350-\353]/g, // E, e
    /[\314-\317]/g, /[\354-\357]/g, // I, i
    /[\322-\330]/g, /[\362-\370]/g, // O, o
    /[\331-\334]/g, /[\371-\374]/g,  // U, u
    /[\321]/g, /[\361]/g, // N, n
    /[\307]/g, /[\347]/g, // C, c
  ];

  var chars = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];

  for (var i = 0; i < diacritics.length; i++)
  {
    s = s.replace(diacritics[i],chars[i]);
  }

  return s;
}

kanayo.util.number_with_precision = function(/*number*/num, /*hash*/options)
{
  options = options == null ? {} : options;
  var precision = options.precision;
  var separator = options.separator == null ? "." : options.separator;
  var delimiter = options.delimiter == null ? "," : options.delimiter;
  
    var n_str;
    if( precision == null )
      n_str = num.toString() 
    else
      n_str = kanayo.util.round(num, precision);

  n_str += '';
  var x = n_str.split('.');
  var x1 = x[0];
  var x2 = x.length > 1 ? separator + x[1] : '';
  
  if( delimiter.length > 0 ){
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + delimiter + '$2');
    }
  }

  return x1 + x2;

}

kanayo.util.round = function(number, precision)
{
  precision = Math.abs(parseInt(precision)) || 0;
  var coefficient = Math.pow(10, precision);
  return Math.round(number*coefficient)/coefficient;
}
//DEPRECATED: use jquery.core-ext.js inside kanayo-jquery instead
kanayo.util.parse_float = function(num_str)
{
  var sep = $t("numbers.decimal_separator");

  if( sep != "." )
  {
    num_str = num_str.replace(sep, ".");
  }

  return parseFloat(num_str);
}

//Combine multiple strings of bits and get represented integer.
kanayo.util.combine_bytes_for_integer = function(byte_array, allow_neg) 
{
  var combined_bit_string = "";
  for (var i = 0; i < byte_array.length; i++) 
  {
    var bit_string = byte_array[i].toString(2);

    //pad bit string to a full byte if less than 8 bits long
    while (bit_string.length < 8) {
      bit_string = "0"+bit_string;
    }
    combined_bit_string = combined_bit_string + bit_string;
  }
  
  if( allow_neg && combined_bit_string[0] == 1 ){
    combined_bit_string = combined_bit_string.replace( /^1/, "0")
    result = -parseInt(combined_bit_string,2);
  } else{
    result = parseInt(combined_bit_string,2);
  }
  return result;
}

//Zero pad numbers
kanayo.util.zero_pad_integer = function(integer, desired_width) {
  var padded_string = ""+integer;

  var diff = desired_width - padded_string.length
  for (var i = 0; i < diff; i++) {
    padded_string = "0"+padded_string;
  }
  return padded_string;
}


kanayo.util.Ieee11073ToDecimal = function( byte1, byte0 ) {
  debugger
  var reservedValues = {
    0x07FE: Number.POSITIVE_INFINITY,
    0x07FF: Number.NaN,
    0x0800: Number.NaN,
    0x0801: Number.NaN,
    0x0802: Number.NEGATIVE_INFINITY }

  var ieee11073 = (byte0 + 0x100*byte1);
  var mantissa = ieee11073 & 0x0FFF;
  if(mantissa in reservedValues )
    return reservedValues[mantissa];
  if (mantissa >= 0x0800)
    mantissa = -(0x1000 - mantissa);
  var exponent = ieee11073 >> 12;
  if (exponent >= 0x08)
    exponent = -(0x10 - exponent);

  return new bigdecimal.BigDecimal( "" +  mantissa + "e" + exponent).toString();
}
