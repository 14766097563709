

  if( typeof kanayo == "undefined" ){ kanayo = {} }
  if( !kanayo.date ){ kanayo.date = {} }



kanayo.date.compare = function(/*Date*/date1, /*Date?*/date2, /*String?*/portion){
    //    summary:
    //        Compare two date objects by date, time, or both.
    //    description:
    //      Returns 0 if equal, positive if a > b, else negative.
    //    date1:
    //        Date object
    //    date2:
    //        Date object.  If not specified, the current Date is used.
    //    portion:
    //        A string indicating the "date" or "time" portion of a Date object.
    //        Compares both "date" and "time" by default.  One of the following:
    //        "date", "time", "datetime"

    // Extra step required in copy for IE - see #3112
    date1 = new Date(+date1);
    date2 = new Date(+(date2 || new Date()));

    if(portion == "date"){
        // Ignore times and compare dates.
        date1.setHours(0, 0, 0, 0);
        date2.setHours(0, 0, 0, 0);
    }else if(portion == "time"){
        // Ignore dates and compare times.
        date1.setFullYear(0, 0, 0);
        date2.setFullYear(0, 0, 0);
    }

    if(date1 > date2){ return 1; } // int
    if(date1 < date2){ return -1; } // int
    return 0; // int
};


kanayo.date.formatHoursMinutes = function( val, blankForZero )
{
    if( val == null ){
      return ""
    }
    var numval = parseInt( val )
    var minutes = Math.floor(numval % 60)
    var hours = Math.floor(numval / 60);

    if( numval == 0 && blankForZero == true ) {
      return  ""
    } else if( hours == 0  ){
      return $t("dates.formats.minutes").replace( "{{minutes}}", "" + minutes)
    } else if( minutes == 0  ){
      return $t("dates.formats.hours").replace( "{{hours}}", "" + hours)
    } else {
      return $t("dates.formats.hours_minutes").replace( "{{hours}}", "" + hours).replace( "{{minutes}}", "" + minutes )
    }
}


kanayo.date.formatHours = function( numval, blankForZero ) {

  var hours = Math.floor(numval / 60);
  
  if( hours == 0 && blankForZero == true  ) {
    return  ""
  } else {
    return $t( "dates.formats.hours" ).replace( "{{hours}}", "" + hours)
  }
}

kanayo.date.formatMinutes = function(numval, blankForZero ) {

  var minutes = Math.floor(numval % 60) 
  
  if( minutes == 0 && blankForZero == true ) {
    return  ""
  } else {
    return $t( "dates.formats.minutes").replace( "{{minutes}}", "" + minutes)
  }
}

kanayo.date.formatDate = function( dateVal )
{
    //var monStr = kanayo.date.monthParseTable[dateVal.getMonth()].display;
    //var dayStr = dateVal.getDate();
    //if( dayStr < 10 )
    //{
    //  dayStr = "0" + dayStr
    //}
    //var yearStr = dateVal.getFullYear();

    //return dateStr = monStr  + " " + dayStr + " " + yearStr;
  return dateVal.format("%x");
}

kanayo.date.formatTime = function( timeVal )
{
  return timeVal.format("%X");

}

kanayo.date.formatDateTime = function( dateVal )
{
  return kanayo.date.formatDate( dateVal ) + " " + kanayo.date.formatTime( dateVal )
}


kanayo.date.durationInYears = function( dateVal )
{
    if( dateVal == null )
    {
        return "";
    }
    else
    {
        var start = new Date( dateVal );
        var now = new Date();
        var years = now.getFullYear() - start.getFullYear();

        if( ( now.getMonth() < start.getMonth() ) ||
                ( now.getMonth() == start.getMonth() &&
                  now.getDate() < start.getDate() ) )
        {
            years--;
        }

        return years;
    }
}

 // cut off 2 digit years 10 years in the future

kanayo.date.twoDigitYearCutoff = function()
{
    var now = new Date();
    return (now.getYear() + 10) % 100;
}

kanayo.date.parseYear = function( rawYear, result )
{
    // trim leading 0s - to prevent octal interpretation
    var trimmedRawYear = rawYear.replace(/^0*/g, "");

    // in case we trimmed all the zeros
    if( trimmedRawYear == "" && rawYear != "" )
    {
       trimmedRawYear = "0";
    }

    var year = parseInt( trimmedRawYear );

    if( !isFinite( year ) )
    {
      year = '';
      if( result ) result.message = "invalid year: " + rawYear;
    }
    else
    {
        if( year <= 99 )
        {
            if( year >= kanayo.date.twoDigitYearCutoff() )
            {
                year += 1900;
            }
            else
            {
                year += 2000;
            }
        }

      if( year > 9999 || year <= 999 )
      {
        if( result ) result.message = "year out of range: " + year;
        year = '';
      }
    }

    return year;
}

kanayo.date.parseTime = function( rawTime  )
{
    var timeArray = rawTime.split( ':' )

    if (timeArray.length == 1)
    {
      rawHour = rawTime
      rawMinute = "0"
    }
    else if (timeArray.length == 2)
    {
      rawHour = timeArray[0]
      rawMinute = timeArray[1]
    }
    else
    {
      return null
    }

    // trim leading 0s - to prevent octal interpretation
    var trimmedRawHour = rawHour.replace(/^0*/g, "");
    var trimmedRawMinute = rawMinute.replace(/^0*/g, "");

    // in case we trimmed all the zeros
    if( trimmedRawHour == "" && rawHour != "" ) trimmedRawHour = "0";
    if( trimmedRawMinute == "" && rawMinute != "" ) trimmedRawMinute = "0";

    var hour = parseInt( trimmedRawHour );
    var minute = parseInt( trimmedRawMinute );

    if( !isFinite( hour ) ) return null;
    if( hour > 23 || hour < 0 ) return null;

    if( !isFinite( minute ) ) return null;
    if( minute > 59 || minute < 0 ) return null;

    var hasPM = rawTime.replace(/[\s\.]*/g, "").search(/PM$/i) != -1
    if( hasPM && hour < 12  )
    {
      hour += 12
    }

    return [hour, minute];
}


kanayo.date.extractMonthName = function( monthName )
{
    if( !monthName )
    {
        return null;
    }

    monthName = monthName.toLowerCase();

    var dp = this.monthParseTable[monthName];

    if( !dp )
    {
         var abr = monthName.substring(0,3);
         dp = this.monthParseTable[abr];
    }

    if( dp )
    {
        var m = dp.index;
        return parseInt( m ) + 1;
    }

    return null;
}

/*
 * Set up table for parsing months from a string.  If a parse table is provided in
 * the global variable MONTH_PARSE_TABLE use it, otherwise use the default.
 */
//kanayo.date.monthParseTable = MONTH_PARSE_TABLE;

if (typeof(kanayo.date.monthParseTable) == "undefined")
{
  kanayo.date.monthParseTable =
    [
      { index: "0",  full: "january",   abr: "jan", display: "Jan", unique: "ja" },
      { index: "1",  full: "february",  abr: "feb", display: "Feb", unique: "f"  },
      { index: "2",  full: "march",     abr: "mar", display: "Mar", unique: "mar"   },
      { index: "3",  full: "april",     abr: "apr", display: "Apr", unique: "ap"   },
      { index: "4",  full: "may",       abr: "may", display: "May", unique: "may"   },
      { index: "5",  full: "june",      abr: "jun", display: "Jun", unique: "jun"   },
      { index: "6",  full: "july",      abr: "jul", display: "Jul", unique: "jul"   },
      { index: "7",  full: "august",    abr: "aug", display: "Aug", unique: "au"  },
      { index: "8",  full: "september", abr: "sep", display: "Sep", unique: "s"   },
      { index: "9",  full: "october",   abr: "oct", display: "Oct", unique: "o"   },
      { index: "10", full: "november",  abr: "nov", display: "Nov", unique: "n"   },
      { index: "11", full: "december",  abr: "dec", display: "Dec", unique: "d"  }
    ];

}
    // set up the monthParseTable to be a hash that can be looked up by full, abr or unique

    for( var i = 0; i < kanayo.date.monthParseTable.length; ++i )
    {
        kanayo.date.monthParseTable[kanayo.util.replaceDiacritics(kanayo.date.monthParseTable[i].full)] = kanayo.date.monthParseTable[i];
        kanayo.date.monthParseTable[kanayo.util.replaceDiacritics(kanayo.date.monthParseTable[i].abr)]  = kanayo.date.monthParseTable[i];
        kanayo.date.monthParseTable[kanayo.util.replaceDiacritics(kanayo.date.monthParseTable[i].unique)]  = kanayo.date.monthParseTable[i];
    }

/** See if the value is a valid month as an integer 1-12 or a string jan, feb, etc. */

kanayo.date.parseMonth = function( rawMonth, result )
{
    // trim leading 0s - to prevent octal interpretation
    var trimmedRawMonth = rawMonth.replace(/^0*/g, "");

    // in case we trimmed all the zeros
    if( trimmedRawMonth == "" && rawMonth != "" )
    {
       trimmedRawMonth = "0";
    }

  var month = parseInt( trimmedRawMonth );

    if( !isFinite( month ) )
    {
        month = kanayo.date.extractMonthName( rawMonth );

        if( month == null )
        {
        result.message = "invalid month: " + rawMonth;
        }
    }
    else if( month > 12 || month <= 0 )
    {
      result.message = "month out of range: " + month;
      month = null;
    }

    return month;
}

/*
  ****** LooseDateParser ******

  Validates a date in a fairly loose way.

  Extra white space is ignored (sometimes extra characters are ignored too)
  Allows various separators: space, comma, slash, dot, dash, backslash
  Allows the month to be a number or a string, matching full name, 3 letter abbrv or partial abbrv
  Allows the year to be 4 or 2 digits, 2 digit dates assume +10 years == past,

  For example:

  January  2 06
  Feb-23-1956
  03/04/96
  n.12.06
  aug. 5, 1887

  Works with three formats:  "m/d/y"  "d/m/y" "y/m/d"


*/

kanayo.date.LooseDateParser = function( theFormat )
{
    this.format = theFormat;

}
var LooseDateParserFuncs =
{
    /** Return an array of values extracted from a string based on separators:
         dot, dash, backslash, space, comma, slash
         assume /'s around month names */

    splitBySeparators: function( val )
  {
    val = kanayo.util.replaceDiacritics(val);

        // trim white space from both ends
        val = val.replace(/(^\s*|\s*$)/g, "");

    // put separators around any contiguous alpha chars,
        val = val.replace( /([a-zA-Z]+)/ , "/$1/" );

      // Make separators uniform - change them all to /
        val = val.replace(  /[:\.\-\\\s,/]/g ,"/");

        // collapse multiple separators to single ones
        val = val.replace(  /\/+/g ,"/");

        // Get rid of separators on the ends
        val = val.replace(/(^(\/)*|(\/*)$)/g, "");  /* trim */

        // split on the /
      return val.split("/");

    },

    /** split a 5 or 6 digit number into day/year */

    splitDayYear: function( val )
  {
      var result = null;

        if( isFinite( parseInt( val ) ) )
        {
            var dy = { d: null, y: null  };

            if( val.length == 5 )
            {
                dy.d = val.substring( 0, 1 );
                dy.y = val.substring( 1 );

                result = dy;
            }
            else if( val.length == 6 )
            {
                dy.d = val.substring( 0, 2 );
                dy.y = val.substring( 2 );

                result = dy;
            }
        }

        return result;

    },


     splitDate: function( val, result )
     {
        var dmy = { d: null, m: null, y: null  };

        // try to split by seps

        var vals = this.splitBySeparators( val, result );
        
        return this.checkDateVals( vals, dmy)
     },
     
     checkDateVals: function( vals, dmy )
     {
        if( vals.length == 3 )
        {
           // Extract month, day year, depending on the format

            if     ( this.format == "m/d/y" )   { dmy.m = vals[0]; dmy.d = vals[1]; dmy.y = vals[2]; }
            else if( this.format == "d/m/y" )   { dmy.m = vals[1]; dmy.d = vals[0]; dmy.y = vals[2]; }
            else if( this.format == "y/m/d" )   { dmy.m = vals[1]; dmy.d = vals[2]; dmy.y = vals[0]; }
            else /*( this.format == "m/d/y" )*/ { dmy.m = vals[0]; dmy.d = vals[1]; dmy.y = vals[2]; }

            // see if month/day are reversed
            if( !isFinite( parseInt( dmy.d ) ) )
            {
              var monthFromName = kanayo.date.extractMonthName( dmy.d );
              if( monthFromName != null )
              {
                month = monthFromName;
                var m = dmy.m
                dmy.m = dmy.d
                dmy.d = m
              }
            }

            return dmy;
        }
        else if( vals.length == 2 )
        {
            // Try to handle a missed space between day/year when there is also a month name

            var dy = this.splitDayYear( vals[0] );

            if( dy != null )
            {
                dmy.m = vals[1];
                dmy.y = dy.y;
                dmy.d = dy.d;

                return dmy;
            }

            dy = this.splitDayYear( vals[1] );

            if( dy != null )
            {
                dmy.m = vals[0];
                dmy.y = dy.y;
                dmy.d = dy.d;

                return dmy;
            }
        }

        return null;

    },


     splitDateTime: function( val, result )
     {
        var dmyhm = { d: null, m: null, y: null, hour: null, minute:null  };

        // try to split by seps

        var vals = this.splitBySeparators( val, result );
        
        if( vals.length >= 2 && vals.length <= 5 )
        {
           // Extract month, day year, depending on the format
           if( vals.length == 2 )
           {
             dmyhm = this.checkDateVals( vals, dmyhm)
           }  
           else
           {
             dmyhm = this.checkDateVals( [vals[0], vals[1], vals[2]], dmyhm)
           }  
            
           if( dmyhm != null )
           {
             var hm = [0,0]
             if( vals.length == 5 || vals.length == 4  )
             {
               var timeStr = vals.length == 4 ? vals[3] : vals[3] + ":" + vals[4]
               hm = kanayo.date.parseTime( timeStr )               
             }
             
             if( hm != null )
             {
               dmyhm.hour  = hm[0]
               dmyhm.minute  = hm[1]
               return dmyhm
             }             
           }
        }
        
        return null
    },


    /** See if the value is a valid month as an integer 1-12 or a string jan, feb, etc. */

    parseDay: function( rawDay, result )
    {
        // trim leading 0s - to prevent octal interpretation
        var trimmedRawDay = rawDay.replace(/^0*/g, "");

      var day = parseInt( trimmedRawDay );

      if( !isFinite( day ) )
      {
        day = null;
        result.message = "invalid day: " + rawDay;
      }
      else if( day > 31 || day <= 0 )
      {
        result.message = "day out of range: " + day;
        day = null;
      }

      return day;
    },

     /** See if the date stands up */

    checkDate: function( year, month, day, result )
    {
        // convert y/m/d to a Date() and compare the computed y/m/d/ to the input

        var newdate = new Date( year, month-1, day );

        if( newdate.getFullYear() != year    ||
            newdate.getMonth()    != month-1 ||
            newdate.getDate()     != day )
        {
          result.message = "invalid date: " + newdate;
          newdate = null;
        }

        return newdate;
    },

    checkDateTime: function( year, month, day, hour, minute, result )
    {
        // convert y/m/d hh:mm to a Date() and compare the computed y/m/d/ hh:mm to the input

        var newdate = new Date( year, month-1, day, hour, minute );

        if( newdate.getFullYear() != year    ||
            newdate.getMonth()    != month-1 ||
            newdate.getDate()     != day ||
            newdate.getHours()    != hour ||
            newdate.getMinutes()  != minute )
        {
          result.message = "invalid date: " + newdate;
          newdate = null;
        }

        return newdate;
    },

     /** Convert to a human readable string based on the expected format */

    convertToString: function( newdate )
    {
        var dateStr;

        var monStr = kanayo.date.monthParseTable[newdate.getMonth()].display;
        var dayStr = newdate.getDate();
        var yearStr = newdate.getFullYear();

        if     ( this.format == "m/d/y" )   dateStr = monStr  + " " + dayStr + " " + yearStr;
        else if( this.format == "d/m/y" )   dateStr = dayStr  + " " + monStr + " " + yearStr;
        else if( this.format == "y/m/d" )   dateStr = yearStr + " " + monStr + " " + dayStr;
        else /*( this.format == "m/d/y" )*/ dateStr = monStr  + " " + dayStr + " " + yearStr;

      return dateStr;
    },

     /** Parse a typed in date */

  parse: function( val )
  {
        // set up the result
        var result =
        {
            dateValue : null,
            isValid : false,
            message : "",
            dateStr : ""
        };

        var err = false;

        // 1. Try to split the string into three fields

        var dmy = this.splitDate( val, result );

        if( dmy == null )
        {
            err = true;
            result.message = "invalid date: (3 fields required) " + val;
        }

        // 2. Is the month a legal number or string

        var month = null;

        if( !err )
        {
            month = kanayo.date.parseMonth( dmy.m, result );
            if( month == null ) err = true;
        }

        // 3. Is the day a legal number. Later we check whether the day/month/year combo is valid

        var day = null;

        if( !err )
        {
            day = this.parseDay( dmy.d, result );
            if( day == null ) err = true;
        }

        // 4. Is the year a valid 2 or 4 digit year?

        var year = null;

        if( !err )
        {
            year = kanayo.date.parseYear( dmy.y, result );
            if( year == '') err = true;
        }

        var newdate;

        // 5. See if the whole date stands up, i.e. reject feb 31 2000

        if( !err )
        {
            newdate = this.checkDate( year, month, day, result );
            if( newdate == null ) err = true;
        }

        // 6. It passes. Fill in the result.

        if( !err )
        {
          result.dateValue = newdate;
          result.isValid = true;
          result.dateStr = this.convertToString( newdate );
        }

        // dump( "err=" + err + " " + result.message + "\n" );

        return result;

    },
    
   parseDateTime: function( val )
  {
        // set up the result
        var result =
        {
            dateValue : null,
            isValid : false,
            message : "",
            dateStr : ""
        };

        var err = false;

        // 1. Try to split the string into 5 fields

        var dmyhm = this.splitDateTime( val, result );

        if( dmyhm == null )
        {
            err = true;
            result.message = "invalid date: (5 fields required) " + val;
        }

        // 2. Is the month a legal number or string

        var month = null;

        if( !err )
        {
            month = kanayo.date.parseMonth( dmyhm.m, result );
            if( month == null ) err = true;
        }

        // 3. Is the day a legal number. Later we check whether the day/month/year combo is valid

        var day = null;

        if( !err )
        {
            day = this.parseDay( dmyhm.d, result );
            if( day == null ) err = true;
        }

        // 4. Is the year a valid 2 or 4 digit year?

        var year = null;

        if( !err )
        {
            year = kanayo.date.parseYear( dmyhm.y, result );
            if( year == '') err = true;
        }

        var newdate;

        // 5. See if the whole date stands up, i.e. reject feb 31 2000

        if( !err )
        {
            newdate = this.checkDateTime( year, month, day, dmyhm.hour, dmyhm.minute, result );
            if( newdate == null ) err = true;
        }

        // 6. It passes. Fill in the result.

        if( !err )
        {
          result.dateValue = newdate;
          result.isValid = true;
          result.dateStr = kanayo.date.formatDateTime( newdate );
        }

        // dump( "err=" + err + " " + result.message + "\n" );

        return result;

    }
}


LooseDateParserFuncs.format = typeof(DATE_PARSE_ORDER) != "undefined"  ? DATE_PARSE_ORDER :  "m/d/y"
kanayo.date.standardDateParser = LooseDateParserFuncs




Date._l10n = {
  days:          typeof(DAY_NAMES) != "undefined"           ? DAY_NAMES           : ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  abbrev_days:   typeof(ABBR_DAY_NAMES) != "undefined"      ? ABBR_DAY_NAMES      : ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  months:        typeof(MONTH_NAMES) != "undefined"         ? MONTH_NAMES         : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  abbrev_months: typeof(ABBR_MONTH_NAMES) != "undefined"    ? ABBR_MONTH_NAMES    : ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  date:          typeof(DEFAULT_DATE_FORMAT) != "undefined" ? DEFAULT_DATE_FORMAT : "%b %d %Y",
  time:          typeof(DEFAULT_TIME_FORMAT) != "undefined" ? DEFAULT_TIME_FORMAT : "%H:%M"};

Date._pad = function(num, len) {
  for (var i = 1; i <= len; i++)
    if (num < Math.pow(10, i))
      return new Array(len-i+1).join(0) + num;
  return num;
};

//format this date in the W3C standard date time format, but leave off the
//timezone. The server is responsible for figuring out the appropriate
//timezone if this date is being submitted.
//This should be used whenever a date is being selected by the user to be
//sent to the server. We omit the TZD because the browser standard (except
//IE) toISOString returns the value 'Z', indicating UTC by default. As this
//is arbitrary and could in fact conflict with the user's intentions, this
//method is preferred by Kanayo.
Date.prototype.toISOStringNoTZD = function() {
  return this.format( "%Y-%m-%dT%H:%M:%S" )
}

Date.prototype.format = function(format) {
  if (format.indexOf('%%') > -1) { // a literal `%' character
    format = format.split('%%');
    for (var i = 0; i < format.length; i++)
      format[i] = this.format(format[i]);
    return format.join('%');
  }
  format = format.replace(/%D/g, '%m/%d/%y'); // same as %m/%d/%y
  format = format.replace(/%r/g, '%I:%M:%S %p'); // time in a.m. and p.m. notation
  format = format.replace(/%R/g, '%H:%M:%S'); // time in 24 hour notation
  format = format.replace(/%T/g, '%H:%M:%S'); // current time, equal to %H:%M:%S
  format = format.replace(/%x/g, Date._l10n.date); // preferred date representation for the current locale without the time
  format = format.replace(/%X/g, Date._l10n.time); // preferred time representation for the current locale without the date
  var dateObj = this;
  return format.replace(/%([aAbhBcCdegGHIjlmMnpPStuUVWwyYzZ])/g, function(match0, match1) {
    return dateObj.format_callback(match0, match1);
  });
}
Date.prototype.format_callback = function(match0, match1) {
  switch (match1) {
    case 'a': // abbreviated weekday name according to the current locale
      return Date._l10n.abbrev_days[this.getDay()];
    case 'A': // full weekday name according to the current locale
      return Date._l10n.days[this.getDay()];
    case 'b':
    case 'h': // abbreviated month name according to the current locale
      return Date._l10n.abbrev_months[this.getMonth()];
    case 'B': // full month name according to the current locale
      return Date._l10n.months[this.getMonth()];
    case 'c': // preferred date and time representation for the current locale
      return this.toLocaleString();
    case 'C': // century number (the year divided by 100 and truncated to an integer, range 00 to 99)
      return Math.floor(this.getFullYear() / 100);
    case 'd': // day of the month as a decimal number (range 01 to 31)
      return Date._pad(this.getDate(), 2);
    case 'e': // day of the month as a decimal number, a single digit is preceded by a space (range ' 1' to '31')
      return this.getDate();
    /*case 'g': // like %G, but without the century
      return ;
    case 'G': // The 4-digit year corresponding to the ISO week number (see %V). This has the same format and value as %Y, except that if the ISO week number belongs to the previous or next year, that year is used instead
      return ;*/
    case 'H': // hour as a decimal number using a 24-hour clock (range 00 to 23)
      return Date._pad(this.getHours(), 2);
    case 'I': // hour as a decimal number using a 12-hour clock (range 01 to 12)
      return Date._pad( (this.getHours() > 12 ?  this.getHours() - 12 : this.getHours() ), 2);
    case 'l': // hour as a decimal number using a 12-hour clock (range 1 to 12)
      return (this.getHours() > 12 ?  this.getHours() - 12 : (this.getHours() == 0 ? 12 : this.getHours()));
    case 'j': // day of the year as a decimal number (range 001 to 366)
      return Date._pad(this.getMonth() * 30 + Math.ceil(this.getMonth() / 2) + this.getDay() - 2 * (this.getMonth() > 1) + (!(this.getFullYear() % 400) || (!(this.getFullYear() % 4) && this.getFullYear() % 100)), 3);
    case 'm': // month as a decimal number (range 01 to 12)
      return Date._pad(this.getMonth() + 1, 2);
    case 'M': // minute as a decimal number
      return Date._pad(this.getMinutes(), 2);
    case 'n': // newline character
      return '\n';
    case 'p': // either `am' or `pm' according to the given time value, or the corresponding strings for the current locale
      return this.getHours() < 12 ? 'am' : 'pm';
    case 'P': // either `am' or `pm' according to the given time value, or the corresponding strings for the current locale
      return this.getHours() < 12 ? 'AM' : 'PM';
    case 'S': // second as a decimal number
      return Date._pad(this.getSeconds(), 2);
    case 't': // tab character
      return '\t';
    case 'u': // weekday as a decimal number [1,7], with 1 representing Monday
      return this.getDay() || 7;
    /*case 'U': // week number of the current year as a decimal number, starting with the first Sunday as the first day of the first week
      return ;
    case 'V': // The ISO 8601:1988 week number of the current year as a decimal number, range 01 to 53, where week 1 is the first week that has at least 4 days in the current year, and with Monday as the first day of the week. (Use %G or %g for the year component that corresponds to the week number for the specified timestamp.)
      return ;
    case 'W': // week number of the current year as a decimal number, starting with the first Monday as the first day of the first week
      return ;*/
    case 'w': // day of the week as a decimal, Sunday being 0
      return this.getDay();
    case 'y': // year as a decimal number without a century (range 00 to 99)
      return this.getFullYear().toString().substr(2);
    case 'Y': // year as a decimal number including the century
      return this.getFullYear();
    /*case 'z':
    case 'Z': // time zone or name or abbreviation
      return ;*/
    default:
      return match0;
  }
}
