"use strict";

/* exported AND_UA_767BT_Ci */
var AND_UA_767BT_Ci = new JS.Class(Bluetooth_Device, {
  initialize: function initialize(device_uuid) {

    var name = "A&D UA-767 Plus BT-Ci Blood Pressure Cuff";
    var mymedic_id = "ADBTBP767CIType";

    return this.callSuper(name, device_uuid, mymedic_id, 1);
  }

});