"use strict";

var AND_UC_351PBT_Ci = new JS.Class(Bluetooth_Device, {
  initialize: function initialize(device_uuid) {

    var name = "A&D UC-351-PBT-Ci Weight Scale";
    var mymedic_id = "ADBTWS351CIType";

    return this.callSuper(name, device_uuid, mymedic_id, 1);
  }

});