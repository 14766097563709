"use strict";

/* globals previousPoint */
var GraphSupport = {
  prepareGraphOptions: function prepareGraphOptions(data, dateRange) {
    var options = JSON.parse('{"grid":{"borderWidth":1,"markings":[{"yaxis":{"from":78},"color":"rgba(255, 0, 0, 0.1)","show":true,"label":"limit"},{"yaxis":{"to":70},"color":"rgba(255, 0, 0, 0.1)","show":true,"label":"limit"}],"hoverable":true,"clickable":true,"maxOutset":10,"tickColor":"#dddddd"},"xaxis":{"mode":"time","min":1425673814000,"max":1428348614000,"labelWidth":80,"monthNames":["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]},"yaxis":{"max":80,"min":40,"tickSize":10,"tickDecimals":0,"autoscaleMargin":0.25,"allowOverflowMin":true,"allowOverflowMax":true,"labelWidth":80},"legend":null,"selection":{"mode":"x"},"series":{"shadowSize":4},"hooks":{}}');

    var legend = { show: false };

    //Filter out all markings that are not target
    function keepMarking(value) {
      return value.label == "target";
    }
    var markings = data.markings.filter(keepMarking);

    options = {
      grid: {
        borderWidth: 1,
        markings: markings,
        hoverable: true,
        clickable: true,
        maxOutset: 10,
        tickColor: "#dddddd"
      },
      xaxis: $.extend({
        timeBase: 'milliseconds',
        autoScale: "none" }, data.xaxis),

      yaxis: $.extend({ autoScale: "none" }, data.yaxis),
      legend: legend,
      selection: { mode: "x" },
      series: { shadowSize: 4 },
      hooks: { draw: GraphSupport.drawHook }
    };

    if (data[0] && data[0].name == "cmate_rag") {
      options.yaxis.ticks = [[0, ""], [1, $t("colors.green")], [2, $t("colors.amber")], [3, $t("colors.red")], [4, ""]];
    }

    options.xaxis.monthNames = Date._l10n.abbrev_months;
    options.xaxis.dayNames = Date._l10n.abbrev_days;

    return options;
  },

  images: function images() {
    return {
      circle_upper: '/images/flot/legend-circle-upper.png',
      circle_lower: '/images/flot/legend-circle-lower.png',
      circle_blue: '/images/flot/legend-circle-blue.png',
      circle_half: '/images/flot/legend-circle-half.png',
      circle_full: '/images/flot/legend-circle-full.png',
      circle: '/images/flot/legend-circle.png',
      triangle: '/images/flot/legend-triangle.png'
    };
  },

  displayVal: function displayVal(val, decimal_places) {
    if (val == "--") {
      return val;
    } else {
      return kanayo.util.number_with_precision(val, {
        precision: decimal_places,
        separator: $t("numbers.decimal_separator"),
        delimiter: $t("numbers.thousands_separator")
      });
    }
  },

  prepareGraphData: function prepareGraphData(data, measurementName) {
    var vitals_data_sets = data.vitals_data_sets || [];
    var remote_manual_data_sets = data.remote_manual_data_sets || [];
    var remote_patient_manual_data_sets = data.remote_patient_manual_data_sets || [];
    var data_source_data_sets = data.data_source_data_sets || [];
    var extra_data_sets = data.extra_data_sets || [];
    var high_threshold_data_sets = data.high_threshold_data_sets || [];
    var low_threshold_data_sets = data.low_threshold_data_sets || [];

    var radiusNormal = 3;

    var measurementSet = data.is_measurement_set;

    var points_options = { show: true, radius: radiusNormal };
    var bars_options = { show: false };
    var lines_options = { show: true };

    if (measurementSet) {
      points_options.show = false;
      lines_options.show = false;
      bars_options = { show: true, barWidth: 0.05, fill: true, lineWidth: 2, align: "center" };
    }

    jQuery.each(data.data_sets, function (index) {

      if (this.name == "cmate_rag") {
        jQuery.each(this.data, function (index) {
          if (this[2] == null) {
            this[2] = {};
          }
          this[2].tooltipDisplay = lookupRagStatus(parseInt(this[1]));
        });
      }
      jQuery.each(this.data, function (index) {
        if (this != null && this[2]) {
          if (this[2]["threshold"] == "high") {
            this[1] = data.yaxis.max;
          } else if (this[2]["threshold"] == "low") {
            this[1] = data.yaxis.min;
          }
        }
      });

      if (index == 0) {
        this.legend = { image: GraphSupport.images().circle };
      }

      var barColor = 'black';
      if (data.measurement_set_colours != null) {
        barColor = data.measurement_set_colours[index];
      }

      if (measurementSet) {
        this.stack = true;
        data.yaxis.tickFormatter = function (val, b) {
          return kanayo.date.formatHoursMinutes(val, true);
        };
      }

      this.bars = jQuery.extend({ fillColor: barColor, color: barColor }, bars_options);
      this.lines = { show: lines_options.show, fill: data.fill };
      this.points = jQuery.extend({ symbol: this.shape, color: "black" }, points_options);

      if (measurementName.indexOf("sbp_dbp") == 0 && (this.name == "sbp" || this.name == "dbp")) {
        this.lines.show = false;
        this.points.show = true;
        this.points.fill = true;
        this.points.fillColor = "white";
        if (this.name == "sbp") {
          this.legend = { image: GraphSupport.images().circle_upper };
        } else {
          this.legend = { image: GraphSupport.images().circle_lower };
        }
      }
    });

    jQuery.each(extra_data_sets, function () {
      this.color = 'black';
      //this.dashes = {show:true, dashLength: 2, lineWidth: 1 };
      this.lines = { show: true, dashes: [2] };
      this.points = { show: false };
      this.shadowSize = 0;
    });

    jQuery.each(vitals_data_sets, function (index) {
      this.color = 'orange';
      this.lines = { show: false, fill: false };
      this.points = jQuery.extend({ symbol: 'circle' }, points_options);
    });

    jQuery.each(remote_manual_data_sets, function (index) {
      this.color = 'purple';
      this.lines = { show: false, fill: false };
      this.points = jQuery.extend({ symbol: 'circle' }, points_options);
    });

    jQuery.each(high_threshold_data_sets, function (index) {
      jQuery.each(this.data, function (index) {
        if (this != null) {
          this[1] = data.yaxis.max;
        }
      });
      this.color = 'brown';
      this.lines = { show: false, fill: false };
      this.points = jQuery.extend({ symbol: 'triangle-up' }, points_options);
      this.points.radius = 4;
    });

    jQuery.each(low_threshold_data_sets, function (index) {
      jQuery.each(this.data, function (index) {
        if (this != null) {
          this[1] = data.yaxis.min;
        }
      });
      this.color = 'brown';
      this.lines = { show: false, fill: false };
      this.points = jQuery.extend({ symbol: 'triangle' }, points_options);
      this.points.radius = 4;
    });

    jQuery.each(remote_patient_manual_data_sets, function (index) {
      this.color = 'blue';
      this.lines = { show: false, fill: false };
      this.points = jQuery.extend({ symbol: 'circle' }, points_options);
    });

    jQuery.each(data_source_data_sets, function (index) {
      this.color = 'black';
      this.lines = { show: false, fill: false };
      this.points = jQuery.extend({ symbol: 'square' }, points_options);
    });

    var combined_data = data.data_sets.concat(vitals_data_sets, remote_manual_data_sets, remote_patient_manual_data_sets, extra_data_sets, low_threshold_data_sets, high_threshold_data_sets, data_source_data_sets);
    combined_data.xaxis = data.xaxis;
    combined_data.yaxis = data.yaxis;
    combined_data.markings = data.markings;

    return combined_data;
  },

  drawHook: function drawHook(plot, ctx) {
    var data = plot.getData();
    var dataSize = 0;
    jQuery.each(data, function () {
      jQuery.each(this.data, function () {
        dataSize += this.length;
      });
    });

    if (dataSize == 0) {
      var placeholderWidth = plot.getPlaceholder().width();
      var x = placeholderWidth / 2;
      var y = 55;

      ctx.font = "9pt Source Sans Pro";
      ctx.textAlign = "center";
      ctx.strokeStyle = "rgba(0,0,0,0.2)";
      ctx.strokeText($t("vitals.no_data"), x, y);
    }
  },

  graphHover: function graphHover(event, pos, item, units, decimal_places, value_names) {
    if (item) {
      if (previousPoint[0] != item.datapoint[0] || previousPoint[1] != item.datapoint[1]) {
        previousPoint = item.datapoint;
        $("#flottooltip").remove();

        var dp = item.series.data[item.dataIndex];
        var disp = GraphSupport.displayPoint(dp, units, decimal_places, value_names);

        GraphSupport.showFlotTooltip(item.pageX, item.pageY, disp[1] + " " + disp[0]);
      }
    } else {
      GraphSupport.clearGraphHover();
    }
  },

  displayPoint: function displayPoint(datapoint, units, decimal_places, value_names) {
    var d = new Date();
    d.setTime(datapoint[0].toFixed(0));

    var measurementDate;
    if (datapoint[2] && datapoint[2].daily) {
      measurementDate = kanayo.date.formatDate(d);
    } else {
      measurementDate = kanayo.date.formatDateTime(d);
    }

    var displayValue = "";

    if (datapoint[2] && datapoint[2].tooltipDisplay != null) {
      displayValue = datapoint[2].tooltipDisplay;
    } else if (value_names != null) {
      displayValue = value_names[datapoint[1]];
    } else {
      var measurementValue = GraphSupport.displayVal(datapoint[1], decimal_places);

      if (datapoint[2] && datapoint[2].hours_and_mins) {
        displayValue = kanayo.date.formatHoursMinutes(datapoint[1], true);
      } else {
        displayValue = measurementValue + " " + units;
      }

      if (datapoint[2]) {
        if (datapoint[2].after) {
          displayValue = measurementValue + " / " + GraphSupport.displayVal(datapoint[2].after, decimal_places) + " " + units;
        } else if (datapoint[2].before) {
          displayValue = GraphSupport.displayVal(datapoint[2].before, decimal_places) + " / " + measurementValue + " " + units;
        } else if (datapoint[2].tooltip) {
          if (datapoint[2].tooltip.str) {
            displayValue = datapoint[2].tooltip.str + " " + units;
          }
        } else if (datapoint[2].sensor_designation) {
          measurementDate = measurementDate + " (" + datapoint[2].sensor_designation + ")";
        }

        if (datapoint[2].tooltip && datapoint[2].tooltip.prestr) {
          displayValue = datapoint[2].tooltip.prestr + displayValue;
        }
      }
    }
    return [measurementDate, displayValue];
  },

  clearGraphHover: function clearGraphHover() {
    $("#flottooltip").remove();
    previousPoint = [];
  },

  showFlotTooltip: function showFlotTooltip(x, y, contents) {
    var flottooltip = $('<div id="flottooltip">' + contents + '</div>').css({
      position: 'absolute',
      display: 'none',
      top: y + 15,
      left: x + 5,
      border: '1px solid #7EABCD',
      padding: '2px 2px 2px 6px',
      'background-color': '#F1F1F1',
      opacity: 0.90,
      'white-space': 'nowrap'
    }).appendTo("body"); //

    var left_edge = parseInt($(flottooltip).css('left'));
    var right_edge = left_edge + $(flottooltip).width();
    var body_width = $('body').width();
    var bleed = right_edge - body_width;

    //Move the tooltip if it bleeds off the body to the right.
    if (bleed > 0) {
      var new_left_edge = left_edge - bleed - 15;
      $(flottooltip).animate({ left: new_left_edge }, 0);
    }

    //Move the tooltip right if it bleeds off the body to the left.
    if (left_edge < 0) {
      $(flottooltip).animate({ left: 15 }, 0);
    }
    $(flottooltip).fadeIn(200).delay(1000 * mymobileConfig.vitals_graph_measurement_tooltip_timeout_sec).fadeOut('slow', GraphSupport.clearGraphHover);
  }
};