"use strict";

//Cordova HealthKit plugin
//https://github.com/Telerik-Verified-Plugins/HealthKit

//HealthKit sample constants
//https://developer.apple.com/reference/healthkit/1627060-healthkit_constants
//https://github.com/mstg/iOS-full-sdk/blob/master/iPhoneOS9.3.sdk/System/Library/Frameworks/HealthKit.framework/Headers/HKTypeIdentifiers.h

//HKUnit Contsants
//https://developer.apple.com/reference/healthkit/hkunit?language=objc
//https://github.com/nst/iOS-Runtime-Headers/blob/master/Frameworks/HealthKit.framework/HKUnit.h

//Unit from String docs
//https://developer.apple.com/reference/healthkit/hkunit/1615733-unitfromstring

var Healthkit = {
  supportedTypes: {
    //Mentioned in Word Doc
    forced_vital_capacity: { identifier: 'HKQuantityTypeIdentifierForcedVitalCapacity', unit: "l", tm_measurement_name: "fvc" },
    heart_rate: { identifier: 'HKQuantityTypeIdentifierHeartRate', unit: 'count/min', tm_measurement_name: "hr" },
    height: { identifier: 'HKQuantityTypeIdentifierHeight', unit: 'cm', tm_measurement_name: "ht" },
    so2: { identifier: 'HKQuantityTypeIdentifierOxygenSaturation', unit: 'percent', tm_measurement_name: "so2" },
    //peak_flow_rate: {identifier: 'HKQuantityTypeIdentifierPeakExpiratoryFlowRate', unit: 'l/min', tm_measurement_name: ""},
    //respiratory_flow_rate: {identifier: 'HKQuantityTypeIdentifierRespiratoryRate', unit: 'count/min', tm_measurement_name: ""},
    steps: { identifier: 'HKQuantityTypeIdentifierStepCount', unit: 'count', tm_measurement_name: "daily_steps" },
    bp_sys: { identifier: 'HKQuantityTypeIdentifierBloodPressureSystolic', unit: 'mmHg', tm_measurement_name: "sbp" },
    bp_dia: { identifier: 'HKQuantityTypeIdentifierBloodPressureDiastolic', unit: 'mmHg', tm_measurement_name: "dbp" },
    //distance: {identifier: 'HKQuantityTypeIdentifierDistanceWalkingRunning', unit: 'm', tm_measurement_name: "fvc"},
    weight: { identifier: 'HKQuantityTypeIdentifierBodyMass', unit: 'kg', tm_measurement_name: "wt" },
    //bmi: {identifier: 'HKQuantityTypeIdentifierBodyMassIndex', unit: 'count', tm_measurement_name: "fvc"},
    blood_glucose: { identifier: 'HKQuantityTypeIdentifierBloodGlucose', unit: 'mmol<180.1558>/l', tm_measurement_name: "bgc" },
    temperature: { identifier: 'HKQuantityTypeIdentifierBodyTemperature', unit: 'degC', tm_measurement_name: "temp" }
    //basal_temperature: {identifier: 'HKQuantityTypeIdentifierBasalBodyTemperature', unit: 'degC', tm_measurement_name: ""},
    //perfusion_index: {identifier: 'HKQuantityTypeIdentifierPeripheralPerfusionIndex', unit: 'percent', tm_measurement_name: ""},

  },

  getMeasurementByName: function getMeasurementByName(name) {
    return Profile.getMeasurementByName;
  },

  _success: function _success(result) {
    console.log("Success: " + result);
  },
  _failure: function _failure(error) {
    console.error("Healthkit Error");
    console.error(error);
  },

  available: false,

  checkAvailability: function checkAvailability() {
    console.log("Checking Healthkit availability");

    return new Promise(function (resolve, reject) {
      Healthkit.available = false;
      window.plugins.healthkit.available(function (result) {
        console.log("Healthkit is available.");
        Healthkit.available = true;
        resolve(result);
      }, function () {
        console.error("Healthkit is not available.");
        reject();
      });
    });
  },

  requestAuthorization: function requestAuthorization(success, failure) {
    console.log("Attempting to authorize Healthkit data.");

    return new Promise(function (resolve, reject) {

      var readTypes = [];
      var writeTypes = [];

      for (var key in Healthkit.supportedTypes) {
        readTypes.push(Healthkit.supportedTypes[key].identifier);
        writeTypes.push(Healthkit.supportedTypes[key].identifier);
      }

      window.plugins.healthkit.requestAuthorization({
        readTypes: readTypes,
        writeTypes: writeTypes
      }, function (result) {
        console.log("Healthkit Authorization granted");
        resolve(result);
      }, function (error) {
        console.error("Healthkit Authorization denied.");
        reject(error);
      });
    });
  },

  getAll: function getAll() {
    console.log("Requesting all data from Healthkit.");

    return new Promise(function (resolve, reject) {
      var promises = [];
      for (var key in Healthkit.supportedTypes) {
        promises.push(Healthkit.querySampleType(key, null, null));
      }

      Promise.all(promises).then(function (values) {
        return resolve(values);
      }, function (reason) {
        return reject(reason);
      });
    });
  },

  checkWriteAuthStatus: function checkWriteAuthStatus(sampleType, success, failure) {
    console.log("Checking if we have permission to read " + sampleType);

    window.plugins.healthkit.checkAuthStatus({
      'type': sampleType.identifier
    }, success, failure);
  },

  querySampleType: function querySampleType(sampleTypeKey, startDate, endDate) {

    var sampleType = Healthkit.supportedTypes[sampleTypeKey];
    console.log("Requesting " + sampleTypeKey + " -- HKIdentifier: " + sampleType.identifier + " HKUnit: " + sampleType.unit);

    //Todo: Check permission and notify user if not granted.
    //According to Apple this is impossible. iOS does not allow you to check if read permissions have been granted.
    // If permission has not been granted, query will return empty as if there is no data.

    if (!startDate) {
      startDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000); // 7 days ago
    }

    if (!endDate) {
      endDate = new Date();
    }

    return new Promise(function (resolve, reject) {
      window.plugins.healthkit.querySampleType({
        'startDate': startDate,
        'endDate': endDate,
        'sampleType': sampleType.identifier,
        'unit': sampleType.unit
      }, function (result) {
        var labelled_result = {};
        labelled_result[sampleTypeKey] = result;
        return resolve(labelled_result);
      }, function (error) {
        return reject(error);
      });
    });
  },

  isDuplicate: function isDuplicate(data) {
    var measurements = Profile.healthkit_measurements;

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = measurements[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var measurement = _step.value;

        var hkDate = new Date(data.startDate);
        var tmDate = new Date(measurement.date);

        var hkQuantity = parseFloat(data.quantity);
        var tmQuantity = parseFloat(measurement.value);

        if (tmDate.getTime() == hkDate.getTime() && tmQuantity == hkQuantity) {
          return true;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return false;
  },

  writeHeartRate: function writeHeartRate() {
    console.log("Writing Heart Rate data");
  },

  readHeight: function readHeight(startDate, endDate) {
    console.log("Sampling Height.");

    return new Promise(function (resolve, reject) {
      Healthkit.querySampleType(Healthkit.supportedTypes.height, startDate, endDate, function (result) {
        return resolve({ name: "height", "values": result });
      }, function (error) {
        return reject(error);
      });
    });
  },

  readWeight: function readWeight(startDate, endDate) {
    console.log("Sampling Weight.");

    return new Promise(function (resolve, reject) {
      Healthkit.querySampleType(Healthkit.supportedTypes.weight, startDate, endDate, function (result) {
        return resolve({ name: "weight", "values": result });
      }, function (error) {
        return reject(error);
      });
    });
  },

  readSo2: function readSo2(startDate, endDate) {
    console.log("Sampling so2.");

    return new Promise(function (resolve, reject) {
      Healthkit.querySampleType(Healthkit.supportedTypes.so2, startDate, endDate, function (result) {
        return resolve({ name: "so2", "values": result });
      }, function (error) {
        return reject(error);
      });
    });
  },

  readHeartRate: function readHeartRate(startDate, endDate) {
    console.log("Sampling Heart Rate.");

    return new Promise(function (resolve, reject) {
      Healthkit.querySampleType(Healthkit.supportedTypes.heart_rate, startDate, endDate, function (result) {
        return resolve({ name: "heart_rate", "values": result });
      }, function (error) {
        return reject(error);
      });
    });
  },

  parseData: function parseData(result) {
    console.log("Parsing Healthkit data");

    var reading = {
      date: new Date(),
      is_current: true,
      is_retake: false,
      data_source_id: Profile.getHealthkitDataSource().id,
      sensor_model_id: Profile.getHealthkitSensorModel().id,
      patient_measurements_attributes: []
    };

    var _iteratorNormalCompletion2 = true;
    var _didIteratorError2 = false;
    var _iteratorError2 = undefined;

    try {
      for (var _iterator2 = result[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
        var hkMeasurement = _step2.value;

        var key = Object.keys(hkMeasurement)[0]; //Each result is labelled. E.G. temperature: [<data>]
        //Discard empty collections
        if (hkMeasurement[key].length == 0) {
          continue;
        }

        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = hkMeasurement[key][Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var hkData = _step3.value;


            var supportedType = Healthkit.supportedTypes[key];
            var tmMeasurement = Profile.getMeasurementByName(supportedType.tm_measurement_name);

            if (supportedType.unit == 'percent') {
              hkData.quantity = hkData.quantity * 100.0;
            }

            // Don't parse duplicate measurements.
            // Need to do this after adjusting percent!
            if (this.isDuplicate(hkData)) {
              continue;
            }

            var measurement = {
              patient_id: Profile.json.patientId,
              measurement_id: tmMeasurement['id'],
              measurement_unit_id: tmMeasurement['measurement-unit-id'],
              numvalue: hkData.quantity,
              active: true,
              date: new Date(hkData.startDate)
            };
            reading.patient_measurements_attributes.push(measurement);
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }
      }

      // Return null if there are no new measurements.
    } catch (err) {
      _didIteratorError2 = true;
      _iteratorError2 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion2 && _iterator2.return) {
          _iterator2.return();
        }
      } finally {
        if (_didIteratorError2) {
          throw _iteratorError2;
        }
      }
    }

    var new_readingsa_count = reading.patient_measurements_attributes.length;
    if (new_readingsa_count == 0) {
      console.log("No new Healthkit readings.");
      return null;
    } else {
      console.log(new_readingsa_count + " new healthkit reading(s).");
    }

    return { reading: reading };
  }
};