"use strict";

/* exported Myglucohealth */
var Myglucohealth = new JS.Class(Bluetooth_Device, {
  initialize: function initialize(device_uuid) {

    var name = "Myglucohealth MGH-BT1 Glucometer";
    var mymedic_id = "MyGlucoHealthBTType";

    return this.callSuper(name, device_uuid, mymedic_id, mymobileConfig.sensorReadingCap);
  }

});