"use strict";

/* exported TaidocTD1261 */
var TaidocTD1261 = new JS.Class(Bluetooth_Device, {
  initialize: function initialize(device_uuid) {

    var name = "Taidoc TD-1261 Ear Thermometer";
    var mymedic_id = "TaiDocBTType";

    return this.callSuper(name, device_uuid, mymedic_id, 1);
  }

});