"use strict";

/* exported Auth */
var Auth = {
  hubSessionInfo: {},

  logout: function logout() {

    postSessionUpdateAsync({ logout_token: this.token() });

    this.hubSessionInfo = {};
    Store.clearAllGraphData();
    Store.clearAll();
    openPage('login ');
  },

  ping: function ping() {
    console.log("pinging...");
    return postSessionUpdateAsync({ ping_token: this.token() }).catch(function (result) {
      if (result.status == 401) {
        Auth.goOffline();
      };
    });
  },

  vcToken: function vcToken() {
    console.log("pinging vc...");
    return postSessionUpdateAsync({ vc_token: this.token(), ping_token: this.token() }).catch(function (result) {
      if (result.status == 401) {
        Auth.goOffline();
      };
    });
  },

  vcTokenClear: function vcTokenClear() {
    console.log("clearing vc...");
    return postSessionUpdateAsync({ vc_token: this.token(), ping_token: this.token(), clear_vc: true }).catch(function (result) {
      if (result.status == 401) {
        Auth.goOffline();
      };
    });
  },

  goOffline: function goOffline() {
    console.log("Going Offline...");

    if (!this.hubSessionInfo) {
      this.hubSessionInfo = {};
    }
    this.hubSessionInfo.offline = true;
    MainPage.updateDisplay();
    VideoPage.updateDisplay();
  },

  loggedIn: function loggedIn() {
    return !!this.hubSessionInfo['patient-name'];
  },

  offline: function offline() {
    return this.hubSessionInfo.offline === true;
  },

  token: function token() {
    return this.hubSessionInfo.token;
  },

  rememberedToken: function rememberedToken() {
    return this.hubSessionInfo['remembered-token'];
  },

  rememberMeActive: function rememberMeActive() {
    return this.hubSessionInfo['remembered-token'] != null;
  },
  patientName: function patientName() {
    return this.hubSessionInfo['patient-name'];
  },

  patientId: function patientId() {
    return this.hubSessionInfo['patient-id'];
  },

  profileId: function profileId() {
    return this.hubSessionInfo['monitor-profile-id'];
  },

  initSession: function initSession(hubInfo) {
    this.hubSessionInfo = hubInfo;
    loadSettings();
  },

  initLogin: function initLogin(rememberMe) {
    $(".generated-page").remove();

    SecureStore.get().clearAllRememberMe();
    if (rememberMe && Auth.rememberedToken()) {
      SecureStore.get().setRememberMe(Auth.rememberedToken());
    }

    loadPrivacyPolicyFromStore();
    IdleHandler.start();
    MainPage.syncNextLoad = false;
    refreshNotifications();
    Store.setLastSyncDate();
    setMainSyncStatus();
  },

  initOffline: function initOffline(patientId, patientName, profileId, callbacks) {
    var info = {
      'patient-id': patientId,
      'monitor-profile-id': profileId,
      'patient-name': patientName,
      offline: true
    };
    this.initSession(info);
    $(".generated-page").remove();
    loadInterviewsFromStore(callbacks);
    loadPrivacyPolicyFromStore();
  }
};