'use strict';

/* global $t BLE_CONTROL kanayo CronTime JS Interviews Mustache bigdecimal moment asNilable asList deviceOS */

var UnitConversion = new JS.Class({
  initialize: function initialize(json) {
    this.conversionFactor = new bigdecimal.BigDecimal(json['conversion-factor']);

    if ($.isNumeric(json['conversion-offset'])) {
      this.conversionOffset = new bigdecimal.BigDecimal(json['conversion-offset']);
    } else {
      this.conversionOffset = new bigdecimal.BigDecimal(0);
    }

    this.measurementName = json['measurement-name'];
    this.rounding = asNilable(json['measurement-rounding-type']);
    this.sensorModel = asNilable(json['model']);
    this.fromUnit = json['from-unit-name'];
    this.toUnit = json['to-unit-name'];
    this.decimalPlaces = parseInt(json['decimal-places']);
  },

  convert: function convert(val, fromUnit, toUnit) {
    if (this.fromUnit == fromUnit && this.toUnit == toUnit) return this.convertVal(val);else if (this.toUnit == fromUnit && this.fromUnit == toUnit) return this.convertValReverse(val);else return null;
  },

  convertVal: function convertVal(val) {
    if (val == null) {
      return null;
    } else {
      var convertedVal = new bigdecimal.BigDecimal(val).multiply(this.conversionFactor, bigdecimal.MathContext.DECIMAL64()).add(this.conversionOffset);
      return convertedVal.setScale(this.decimalPlaces, this.roundMode());
    }
  },

  convertValReverse: function convertValReverse(val) {
    if (val == null) {
      return null;
    } else {
      var convertedVal = new bigdecimal.BigDecimal(val).subtract(this.conversionOffset).divide(this.conversionFactor, bigdecimal.MathContext.DECIMAL64());
      return convertedVal.setScale(this.decimalPlaces, this.roundMode());
    }
  },

  roundMode: function roundMode() {
    var roundModes = {
      no_round: bigdecimal.RoundingMode.UNNECESSARY(),
      floor: bigdecimal.RoundingMode.FLOOR(),
      down: bigdecimal.RoundingMode.DOWN(),
      half_up: bigdecimal.RoundingMode.HALF_UP(),
      half_even: bigdecimal.RoundingMode.HALF_EVEN(),
      half_down: bigdecimal.RoundingMode.HALF_DOWN(),
      up: bigdecimal.RoundingMode.UP(),
      ceiling: bigdecimal.RoundingMode.CEILING()
    };

    if (this.rounding == null) return roundModes.half_up;else {
      var rMode = roundModes[this.rounding];
      if (rMode == null) {
        rMode = roundModes.half_up;
      }
      return rMode;
    }
  }
});

var PatientPrompt = new JS.Class({
  id: null,
  measurementName: null,
  promptText: null,
  trigger: null,
  unitLabel: null,
  unitName: null,

  initialize: function initialize(promptJson) {
    this.id = parseInt(promptJson["id"]);
    this.measurementName = promptJson["measurement-name"];
    this.promptText = promptJson["prompt-text"];
    this.trigger = promptJson["trigger"];
    this.unitLabel = promptJson["unit-label"];
    this.unitName = promptJson["unit-name"];
  },

  evaluate: function evaluate(readingValue) {
    if (readingValue == null || $.trim(readingValue.length) == 0) {
      return false;
    }

    var triggerString = this.trigger.replace("and", " && ");
    var pos = triggerString.indexOf("<");
    if (pos >= 0) {
      triggerString = [triggerString.slice(0, pos - 1), readingValue, triggerString.slice(pos - 1)].join("");
    }
    pos = triggerString.indexOf(">");
    if (pos >= 0) {
      triggerString = [triggerString.slice(0, pos - 1), readingValue, triggerString.slice(pos - 1)].join("");
    }

    console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");
    console.log(triggerString);
    var res = eval(triggerString);
    console.log(res);
    console.log("<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<");

    return res;
  }

});

var Schedule = new JS.Class({
  id: null,
  interview: null,
  time: null,
  afterWindow: null,
  beforeWindow: null,
  day_of_month: null,
  last_completed: null,
  start_date: null,
  end_date: null,
  every_no_times: null,

  initialize: function initialize(scheduleJson, timeJson, updateGlobalSchedule) {
    if (timeJson != null) {
      this.id = parseInt(timeJson['id']);
      this.afterWindow = parseInt(timeJson['after-window']);
      this.beforeWindow = parseInt(timeJson['before-window']);
      this.time = $.extend({}, timeJson);

      if (timeJson['last-completed'] != null) {
        this.last_completed = new Date(timeJson['last-completed']);
      }

      if (timeJson.days != undefined && timeJson.days.day != undefined) {
        this.time.days = asList(timeJson.days.day);
      } else if (timeJson.days != undefined && timeJson.days['day-of-month'] != undefined) {
        this.day_of_month = timeJson.days['day-of-month'];
        this.time.days = asList();
      } else if (timeJson.days != undefined && timeJson.days != "" && timeJson.days.indexOf(',') > 0) {
        this.time.days = asList(timeJson.days.split(','));
      } else if (timeJson.days != undefined && timeJson.days != "") {
        this.time.days = asList(timeJson.days);
      } else {
        this.time.days = asList();
      }
      this.time.cronDays = this.time.days.join(",");
      var minuteStr = "" + parseInt(timeJson.minute);
      if (minuteStr.length == 1) minuteStr = "0" + minuteStr;
      var hourStr = "" + parseInt(timeJson.hour);
      if (hourStr.length == 1) hourStr = "0" + hourStr;
      var adjusted_start_time = hourStr + ":" + minuteStr;
      var schedule_start_raw = moment("2014-01-01 " + adjusted_start_time);
      var schedule_start = schedule_start_raw.add(-1 * this.beforeWindow, 'minutes');
      this.time.hourInt = schedule_start.format('HH');
      this.time.hour = this.time.hourInt;
      this.time.minuteInt = schedule_start.format('mm');
      this.time.minute = this.time.minuteInt;

      this.start_date = timeJson["start-time"];
      this.end_date = timeJson["end-time"];
      this.every_no_times = timeJson['every-no-of-times'] || 1;
    }
    this.displayText = this.scheduleText();
    this.interviewName = scheduleJson['interview-name'];

    if (updateGlobalSchedule) {
      this.interview = Interviews.findById(scheduleJson['interview-id']);
      this.interview.version = scheduleJson['interview-version'];
      this.interview.addSchedule(this);
    }
  },

  getScheduleIDStr: function getScheduleIDStr() {
    return "[" + this.every_no_times + "]" + "[" + this.time.cronDays + "]" + "[" + this.time.hourInt + ":" + this.time.minuteInt + "]" + "[" + this.day_of_month + "]" + "[" + this.start_date + "]" + "[" + this.end_date + "]";
  },

  getCronStr: function getCronStr() {
    //Unscheduled
    if (this.time == undefined) {
      return;
    }
    // warning: used as id
    // Every week
    if (this.every_no_times == 1 && this.time.days.length > 0) {
      return Mustache.render("{{minute}} {{hour}} * * {{cronDays}}", this.time);
    }
    // every X of the month
    if (this.day_of_month != undefined) {
      return;
    }
    // one time
    if (this.day_of_month == undefined && this.time.days.length == 0) {
      var start_date_split = this.start_date.split("-");
      var mon = start_date_split[1] - 1;
      var day = start_date_split[2];
      return Mustache.render("{{minute}} {{hour}} " + day + " " + mon + " * ", this.time);
    }
    if (this.every_no_times > 1) {
      return;
    }
    return Mustache.render("{{minute}} {{hour}} * * mon", this.time);
  },

  getComplexNextDate: function getComplexNextDate(today_date) {
    var today_date_moment = moment(today_date).clone();
    var start_date_moment = moment(this.start_date, "YYYY-MM-DD").clone();
    var next;

    //Unscheduled
    if (this.time == undefined) {
      return;
    }

    // repeating day of week
    if (this.time.days.length > 0) {
      while (moment(this.getNextDateDayOfWeek(start_date_moment)).startOf('day').diff(today_date_moment, 'day') < 0) {
        start_date_moment = start_date_moment.add(this.every_no_times, 'weeks');
      }
      next = this.getNextDateDayOfWeek(start_date_moment);
    } else {
      if (this.start_date == "" || this.start_date === undefined) {
        start_date_moment = today_date_moment.clone();
      }
      var next_date = Math.min(this.day_of_month, moment(start_date_moment).endOf('month').date());
      if (next_date < this.day_of_month) {
        start_date_moment = start_date_moment.add(1, 'month');
      }
      start_date_moment.date(next_date);

      while (start_date_moment.diff(today_date_moment, 'days') < 0) {
        start_date_moment = start_date_moment.add(this.every_no_times, 'month');
        next_date = Math.min(this.day_of_month, moment(start_date_moment).endOf('month').date());
        start_date_moment.date(next_date);
      }
      next = this.getNextDateMonth(start_date_moment);
    }
    return next;
  },
  startsOnDate: function startsOnDate(startDate) {
    var nextSchedule = this.computeNextDate(startDate).reminderStart;
    if (moment(startDate).isSame(nextSchedule, 'day')) {
      return true;
    }
    return false;
  },

  computeNextDate: function computeNextDate(now) {
    // Unscheduled
    if (this.time == undefined) {
      return undefined;
    }

    var start = moment(now).startOf('day').toDate();
    var result = this.calculateNextDate(start);
    var adjusted_start = moment(start);

    var days_to_check = Math.ceil((this.beforeWindow + this.afterWindow) / 60 / 24);
    if (result == undefined || false == moment(now).isBetween(result.reminderStart, result.reminderEnd)) {
      while (days_to_check >= 0) {
        adjusted_start = adjusted_start.clone().add(-1, 'day');
        var temp_result = this.calculateNextDate(adjusted_start);

        var dateKey = adjusted_start.get('year') + "-" + adjusted_start.get('month') + "-" + adjusted_start.get('date');
        var doneToday = false;

        var registeredAsComplete = false;

        if (this.interview) {
          var completedTimes = Interviews.getCompletedTimes(dateKey, this.interview.id, this.getScheduleIDStr());
          var currentSchedule = this.calculateNextDate(adjusted_start._d);

          if (this.last_completed && currentSchedule && this.last_completed > currentSchedule.reminderStart && this.last_completed < currentSchedule.reminderEnd) {
            registeredAsComplete = true;
          }

          doneToday = completedTimes.length > 0 || registeredAsComplete;
        }

        if (!doneToday && temp_result != undefined && moment(now).isBetween(temp_result.reminderStart, temp_result.reminderEnd)) {
          result = temp_result;
        }
        days_to_check = days_to_check - 1;
      }
    }
    return result;
  },
  calculateNextDate: function calculateNextDate(start) {
    var result;

    //Unscheduled
    if (this.time == undefined) {
      return;
    }

    var cronStr = this.getCronStr();
    var next;
    if (cronStr != undefined) {
      var ct = new CronTime(cronStr);
      next = ct._getNextDateFrom(start);
    } else {
      next = this.getComplexNextDate(start);
    }

    // One time
    if (this.day_of_month == undefined && this.time.days.length == 0) {
      result = {
        reminderStart: moment(next).toDate(),
        reminderEnd: moment(next).add(this.beforeWindow, 'minutes').add(this.afterWindow, 'minutes').toDate(),
        scheduleDate: moment(next).startOf('day').toDate(),
        scheduleIdStr: this.getScheduleIDStr()
      };

      if (moment(next).isBefore(this.start_date, 'day')) {
        return result;
      } else if (moment(next).isSame(this.start_date, 'day')) {
        return result;
      } else {
        return;
      }
    }

    //Past end date
    if (this.end_date) {
      var end_date = moment(this.end_date, "YYYY-MM-DD");
      if (moment(next).isAfter(end_date, 'day')) {
        return;
      }
    }

    // before start date
    if (this.start_date) {
      if (moment(start).isBefore(this.start_date, 'day')) {
        return this.calculateNextDate(this.start_date);
      }
    }

    result = {
      reminderStart: moment(next).toDate(),
      reminderEnd: moment(next).add(this.beforeWindow, 'minutes').add(this.afterWindow, 'minutes').toDate(),
      scheduleDate: next,
      scheduleIdStr: this.getScheduleIDStr()
    };
    return result;
  },

  scheduleText: function scheduleText() {
    return this.scheduleTime() + " " + this.daysText() + " " + this.start_date + " " + this.end_date;
  },

  scheduleTime: function scheduleTime() {
    if (this.time == undefined) {
      return "";
    } else {
      var dt = new Date(2001, 1, 1, this.time.hourInt, this.time.minuteInt);
      return dt.format('%X');
    }
  },

  daysText: function daysText() {
    if (this.time == undefined) {
      return $t('dates.names.unschedule');
    }

    var daysStr = this.time.days.concat([]).sort().join('|');
    var str = "";
    if (this.time.days.length != 0) {
      if (this.every_no_times > 1) {
        str = str + $t('dates.names.repeat_weekly');
        str = str.replace("{{repeat}}", $t('numbers.ordinal_numbers.' + this.every_no_times));
      } else if (this.time.days.length < 7) {
        str = str + $t('dates.names.every_week');
      } else {
        str = "{{days}}";
      }
      var days = "";
      if (this.time.days.length == 7) days = $t('dates.names.every_day');else if (this.time.days.length == 5 && daysStr == "fri|mon|thu|tue|wed") days = $t('dates.names.weekdays');else if (this.time.days.length == 2 && daysStr == "sat|sun") days = $t('dates.names.weekends');else {
        var dayNames = [];
        for (var i = 0; i < this.time.days.length; i++) {
          dayNames.push($t('dates.abbrev_days.' + this.time.days[i]));
        }
        days = dayNames.join(", ");
      }
      str = str.replace("{{days}}", days);
      str = str.replace("[[days]]", days);
    } else {

      if (this.day_of_month) {
        if (this.every_no_times == 1) str = $t('dates.names.monthly').replace("{{day}}", $t('numbers.ordinal_numbers.' + this.day_of_month));else {
          str = str + $t('dates.names.repeat_monthy').replace("{{day}}", $t('numbers.ordinal_numbers.' + this.day_of_month));
          str = str.replace("{{repeat}}", $t('numbers.ordinal_numbers.' + this.every_no_times));
        }
      } else if (this.day_of_month == undefined && this.time.days.length == 0) {
        var split_date = this.start_date.split("-");
        var d = new Date();
        d.setYear(split_date[0]);
        d.setMonth(split_date[1] - 1);
        d.setDate(split_date[2]);
        var date_str = kanayo.date.formatDate(d);
        str = $t('dates.names.only_once').replace("{{day}}", date_str);
      } else {
        str = 'Unknown';
      }
    }
    return str;
  },
  getNextDateMonth: function getNextDateMonth(start_date_moment) {
    var cron_str = Mustache.render("{{minute}} {{hour}} " + start_date_moment.date() + " * *", this.time);
    var ct = new CronTime(cron_str);
    var next = ct._getNextDateFrom(start_date_moment);
    return next;
  },

  getNextDateDayOfWeek: function getNextDateDayOfWeek(start_date_moment) {
    var cron_str = Mustache.render("{{minute}} {{hour}} * * {{cronDays}}", this.time);
    var ct = new CronTime(cron_str);
    var next = ct._getNextDateFrom(start_date_moment);
    return next;
  },

  getFollowingTimes: function getFollowingTimes(start_date, amount) {
    var following_times = [];
    var next_end_date = start_date;
    for (var i = 0; i < amount; i++) {

      if (this.time == undefined) {
        break;
      }
      var next_schedule = this.calculateNextDate(next_end_date);
      if (next_schedule) {
        //use the end time in the call to calculateNextDate, this will get the next scheduled event.
        var next_start_date = next_schedule["reminderStart"];
        following_times.push(next_start_date);
        next_end_date = new Date(next_start_date);
        next_end_date = next_end_date.setDate(next_end_date.getDate() + 1);
      } else {
        break;
      }
    }
    return following_times;
  }

});

var Profile = {
  schedules: null,
  auto_retakes: null,
  kit: null,
  sensor_type_restrictions: null,
  unit_conversions: [],
  data_sources: [],
  connected_sources: [],
  sensor_models: [],
  measurements: [],
  measurement_units: [],
  healthkit_measurements: [],
  video_conferencing: null,
  myassist: null,
  unread_message_count: 0,
  patient_prompts: [],
  consent_granted: false,

  init: function init(json) {
    this.json = json;
    var pJson = json['monitor-profile'];
    this.schedules = this.readSchedules(json, true);
    this.auto_retakes = asList(pJson["limit-alert"]);
    this.kit = this.readKit(json);
    this.sensor_type_restrictions = this.readSensorTypeRestrictions(json);
    this.unit_conversions = this.readUnitConversions(pJson);
    this.data_sources = this.readDataSources(pJson);
    this.connected_sources = this.readConnectedSources(pJson);
    this.sensor_models = this.readSensorModels(pJson);
    this.measurements = this.readMeasurements(pJson);
    this.measurement_units = this.readMeasurementUnits(pJson);
    this.video_conferencing = this.readVideoConferencing(pJson);
    this.myassist = this.readMyAssist(pJson);
    this.unread_message_count = this.readUnreadMessageCount(pJson);
    this.patient_prompts = this.readPrompts(pJson);

    if (deviceOS == "iOS" && mymobileConfig.healthKitEnabled) {
      this.healthkit_measurements = this.readHealthkitMeasurements(pJson);
    }

    this.consent_granted = pJson["consent-granted"] == "true";

    Interviews.updateSchedule();
  },

  readUnreadMessageCount: function readUnreadMessageCount(json) {
    if (json['unread-messages-count']) {
      return parseInt(json['unread-messages-count']);
    }

    return 0;
  },

  readPrompts: function readPrompts(json) {
    var prompts = [];

    if (json["prompt"]) {
      var promptList = asList(json["prompt"]);
      for (var i = 0; i < promptList.length; i++) {
        var promptJson = promptList[i];
        if (promptJson) {
          prompts.push(new PatientPrompt(promptJson));
        }
      }
    }

    return prompts;
  },

  getPrompts: function getPrompts(name) {
    var thePrompts = [];
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = this.patient_prompts[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var aPrompt = _step.value;

        if (aPrompt.measurementName === name) {
          thePrompts.push(aPrompt);
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return thePrompts;
  },

  checkPromptLimits: function checkPromptLimits(parentDiv) {
    var promptMsg = [];
    console.log("checkPromptLimits");
    $(".vitals-input", parentDiv).each(function () {
      var input = this;
      var measurementName = $(input).attr('name');
      var measurementValue = $(this).val().replace(",", ".");

      var prompts = Profile.getPrompts(measurementName);
      var _iteratorNormalCompletion2 = true;
      var _didIteratorError2 = false;
      var _iteratorError2 = undefined;

      try {
        for (var _iterator2 = prompts[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
          var prompt = _step2.value;

          if (prompt.evaluate(measurementValue)) {
            promptMsg.push(prompt.promptText);
            break;
          }
        }
      } catch (err) {
        _didIteratorError2 = true;
        _iteratorError2 = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion2 && _iterator2.return) {
            _iterator2.return();
          }
        } finally {
          if (_didIteratorError2) {
            throw _iteratorError2;
          }
        }
      }
    });

    return promptMsg;
  },

  readSchedules: function readSchedules(json, updateGlobalSchedule) {
    var pJson = json['monitor-profile'];
    var scheds = [];
    var ilist = asList(pJson.schedule);
    for (var i = 0; i < ilist.length; i++) {
      var scheduleJson = ilist[i];
      if (scheduleJson.time != undefined) {
        var timeList = asList(scheduleJson.time);
        for (var timeIndex = 0; timeIndex < timeList.length; timeIndex++) {
          scheds.push(new Schedule(scheduleJson, timeList[timeIndex], updateGlobalSchedule));
        }
      } else {
        scheds.push(new Schedule(ilist[i], null, updateGlobalSchedule));
      }
    }
    return scheds;
  },

  readUnitConversions: function readUnitConversions(json) {
    var ucs = [];
    if (json["unit-conversions"]) {
      var ucList = asList(json["unit-conversions"]["unit-conversion"]);
      for (var i = 0; i < ucList.length; i++) {
        ucs.push(new UnitConversion(ucList[i]));
      }
    }
    return ucs;
  },

  readDataSources: function readDataSources(json) {
    var dataSources = [];
    if (json["data-sources"]) {
      dataSources = asList(json["data-sources"]["data-source"]);
    }
    return dataSources;
  },

  readConnectedSources: function readConnectedSources(json) {
    var connectedSources = [];
    if (json["connected-sources"]) {
      connectedSources = asList(json["connected-sources"]["connected-source"]);
    }
    return connectedSources;
  },

  readSensorModels: function readSensorModels(json) {
    var sensorModels = [];
    if (json["sensor-models"]) {
      sensorModels = asList(json["sensor-models"]["sensor-model"]);
    }
    return sensorModels;
  },

  readMeasurements: function readMeasurements(json) {
    var measurements = [];
    if (json["measurements"]) {
      measurements = asList(json["measurements"]["measurement"]);
    }
    return measurements;
  },

  readMeasurementUnits: function readMeasurementUnits(json) {
    var measurementUnits = [];
    if (json["measurement-units"]) {
      measurementUnits = asList(json["measurement-units"]["measurement-unit"]);
    }
    return measurementUnits;
  },

  readVideoConferencing: function readVideoConferencing(json) {
    var vc = {
      enabled: false,
      code: null
    };
    if (json["video-call-enabled"]) {
      vc.enabled = json["video-call-enabled"] == "true";
      vc.code = json["video-call-code"];
    }
    return vc;
  },

  readMyAssist: function readMyAssist(json) {
    var myassist = {
      patient_enabled: true
    };
    if (json["myassist-enabled"] !== undefined) {
      myassist.patient_enabled = json["myassist-enabled"] == "true";
    }
    return myassist;
  },

  readHealthkitMeasurements: function readHealthkitMeasurements(json) {
    var measurements = [];
    if (json["healthkit-measurements"]) {
      measurements = asList(json["healthkit-measurements"]["patient-measurement"]);
    }
    return measurements;
  },

  readKit: function readKit(json) {
    var kitJSON = json['monitor-profile']['kit'];

    if (!kitJSON) {
      console.error("could not read sensor kit from profile JSON");
      return {};
    }

    var kit = {};
    kit.id = kitJSON.id;
    kit.description = kitJSON.description;

    var sensors = asList(kitJSON.sensors.sensor);

    //Create sensor device for each sensor in kit
    $.each(sensors, function (index, sensor) {
      var device_model_identifier = sensor["sensor-type-identification"];
      sensor.device = null;
      $.each(BLE_CONTROL.supported_devices, function (index, device) {
        if (device.model_identifier == device_model_identifier && $.inArray(deviceOS, device.supported_platforms) != -1) {
          //Sanitize class name white space
          var sanitized_class_name = device.class_name.replace(/\W+/g, "");
          try {
            sensor.identification = sensor.identification.toUpperCase();
            var creation_string = "new " + sanitized_class_name + "('" + sensor.identification + "');";
            sensor.device = eval(creation_string);
            sensor.device.serial = sensor['sensor-serial'];
          } catch (err) {
            console.error("Could not initialize device from sensor-model-name in profile: " + sanitized_class_name);
            console.error(err);
            sensor.device = null;
          }
        }
      });
    });

    kit.sensors = sensors;
    return kit;
  },

  readSensorTypeRestrictions: function readSensorTypeRestrictions(json) {
    var restrictions = [];

    if (json['monitor-profile'] == null || json['monitor-profile']['sensor-type-restriction'] == null) {
      return restrictions;
    } else {
      var all_restrictions = asList(json['monitor-profile']['sensor-type-restriction']);
    }

    //Only save restrictions with a hub-type that is mymobile
    var restriction;
    for (var i = 0; i < all_restrictions.length; i++) {
      restriction = all_restrictions[i];
      if (restriction['hub-type-name'] == 'mymobile') {
        restrictions.push(restriction);
      }
    }

    return restrictions;
  },

  getMeasurementByName: function getMeasurementByName(name) {
    var _iteratorNormalCompletion3 = true;
    var _didIteratorError3 = false;
    var _iteratorError3 = undefined;

    try {

      for (var _iterator3 = this.measurements[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
        var measurement = _step3.value;

        if (measurement.name == name) {
          return measurement;
        }
      }
    } catch (err) {
      _didIteratorError3 = true;
      _iteratorError3 = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion3 && _iterator3.return) {
          _iterator3.return();
        }
      } finally {
        if (_didIteratorError3) {
          throw _iteratorError3;
        }
      }
    }

    return null;
  },

  getHealthkitSensorModel: function getHealthkitSensorModel() {
    var hkSensorModel;
    for (var i = 0; i < this.sensor_models.length; i++) {
      if (this.sensor_models[i].model == "Healthkit") {
        hkSensorModel = this.sensor_models[i];
      }
    }
    if (!hkSensorModel) {
      console.error("Healthkit sensor model not found.");
    }
    return hkSensorModel;
  },

  getHealthkitDataSource: function getHealthkitDataSource() {
    var hkDataSource;
    for (var i = 0; i < this.data_sources.length; i++) {
      if (this.data_sources[i].name == "healthkit" && this.data_sources[i].active == "true") {
        hkDataSource = this.data_sources[i];
      }
    }
    if (!hkDataSource) {
      console.error("Healthkit not listed in monitor profile data sources!");
    }
    return hkDataSource;
  },

  getHealthkitConnectedSource: function getHealthkitConnectedSource() {
    var hkConnectedSource;
    var hkDataSource = this.getHealthkitDataSource();
    for (var i = 0; i < this.connected_sources.length; i++) {
      if (this.connected_sources[i]["data-source-id"] == hkDataSource.id) {
        hkConnectedSource = this.connected_sources[i];
      }
    }
    if (!hkConnectedSource) {
      console.error("Healthkit not listed in monitor profile connected sources!");
    }
    return hkConnectedSource;
  },

  healthKitEnabled: function healthKitEnabled() {
    var hkDataSource = this.getHealthkitDataSource();
    if (!hkDataSource) {
      return false;
    }

    var hkConnectedSource = this.getHealthkitConnectedSource();
    if (!hkConnectedSource) {
      return false;
    }

    return true;
  },

  videoConferencingEnabled: function videoConferencingEnabled() {
    if (!mymobileConfig.enableVideoConferencing) {
      return false;
    } else if (mymobileConfig.enableVideoConferencingPerPatient) {
      return this.video_conferencing != null && this.video_conferencing.enabled;
    } else {
      return true;
    }
  },

  myAssistEnabled: function myAssistEnabled() {
    return deviceOS == 'Android' && mymobileConfig.enable_myassist && (this.myassist == null || this.myassist.patient_enabled);
  },

  hasSensorTypeRestriction: function hasSensorTypeRestriction(sensorType) {

    if (sensorType == "ECG") {
      return true;
    }

    if (this.sensor_type_restrictions == null) {
      console.error("Searching sensor type restrictions before they are initialized.");
      throw "Sensor restrictions not initialized.";
    }

    var matching_restrictions = this.sensor_type_restrictions.filter(function (restriction) {
      return restriction['sensor-type-name'] == sensorType;
    });

    if (matching_restrictions.length > 0) {
      return true;
    }
    return false;
  },

  getSensorTypeRestrictions: function getSensorTypeRestrictions(sensorType) {
    if (this.sensor_type_restrictions == null) {
      console.error("Searching sensor type restrictions before they are initialized.");
      throw "Sensor restrictions not initialized.";
    }

    var matching_restrictions = this.sensor_type_restrictions.filter(function (restriction) {
      return restriction['sensor-type-name'] == sensorType;
    });
    return matching_restrictions;
  },

  findRetake: function findRetake(measurementName) {
    for (var i = 0; i < this.auto_retakes.length; i++) {
      if (this.auto_retakes[i]["measurement-name"] == measurementName) {
        var retakeLimits = this.auto_retakes[i];
        var retake = {
          greater: 0, less: 0
        };

        if (retakeLimits["auto-retake"] == "significant_limits") {
          retake.greater = retakeLimits["alert-high"];
          retake.less = retakeLimits["alert-low"];
        } else if (retakeLimits["auto-retake"] == "marginal_limits") {
          retake.greater = retakeLimits["marginal-high"];
          retake.less = retakeLimits["marginal-low"];
        } else {
          retake = null;
        }
        return retake;
      }
    }
    return null;
  },

  getSensorByType: function getSensorByType(sensorTypeName) {
    if (!this.kit || !this.kit.sensors || !sensorTypeName) {
      return null;
    }

    var first_eligible_sensor = null;
    for (var index = 0; index < this.kit.sensors.length; index++) {
      var sensor = this.kit.sensors[index];
      if (sensor["sensor-type-name"] == sensorTypeName && Profile.getSensorTypeRestrictions(sensorTypeName) <= 0) {
        first_eligible_sensor = sensor;
        break;
      }
    }

    //Check if this sensor is excluded


    return first_eligible_sensor;
  },

  getSensorByIdentifier: function getSensorByIdentifier(identifier) {
    if (!this.kit || !this.kit.sensors) {
      return null;
    }

    var found_sensor = null;
    for (var index = 0; index < this.kit.sensors.length; index++) {
      var sensor = this.kit.sensors[index];
      if (sensor['identification'] == identifier && Profile.getSensorTypeRestrictions(sensor["sensor-type-name"]) <= 0) {
        found_sensor = sensor;
        break;
      }
    }
    return found_sensor;
  },

  getSensorByTMId: function getSensorByTMId(id) {
    if (!this.kit || !this.kit.sensors) {
      return null;
    }

    var found_sensor = null;
    for (var index = 0; index < this.kit.sensors.length; index++) {
      var sensor = this.kit.sensors[index];
      if (sensor['id'] == id && Profile.getSensorTypeRestrictions(sensor["sensor-type-name"]) <= 0) {
        found_sensor = sensor;
        break;
      }
    }
    return found_sensor;
  },

  convertMeasurementUnits: function convertMeasurementUnits(rawValue, fromUnit, toUnit, measurementName, sensorModel, decimalPlaces) {
    var decval = new bigdecimal.BigDecimal(rawValue).setScale(decimalPlaces, bigdecimal.RoundingMode.HALF_UP());

    if (fromUnit.toLowerCase() == toUnit.toLowerCase()) return decval.toString();else {
      var conv = this.findConverter(measurementName, sensorModel, fromUnit, toUnit);

      if (conv == null) {
        console.log("---- MISSING Unit Conversion: " + measurementName + " fr: " + fromUnit + " to: " + toUnit);
        return decval.toString();
      } else {
        var covertedVal = conv.convert(rawValue, fromUnit, toUnit);
        return covertedVal.toString();
      }
    }
  },

  findConverter: function findConverter(measurementName, sensorModel, fromUnit, toUnit) {
    var convs = this.unit_conversions.filter(function (uc) {
      return uc.measurementName.toLowerCase() == measurementName.toLowerCase();
    });
    var unitConvs = convs.filter(function (uc) {
      return uc.fromUnit.toLowerCase() == fromUnit.toLowerCase() && uc.toUnit.toLowerCase() == toUnit.toLowerCase();
    });
    var modelConvs = unitConvs.filter(function (uc) {
      return uc.sensorModel == sensorModel;
    });
    if (modelConvs.length > 0) {
      return modelConvs[0];
    }

    var nilModelConvs = unitConvs.filter(function (uc) {
      return uc.sensorModel == null;
    });
    if (nilModelConvs.length > 0) {
      return nilModelConvs[0];
    }

    var revUnitConvs = convs.filter(function (uc) {
      return uc.fromUnit.toLowerCase() == toUnit.toLowerCase() && uc.toUnit.toLowerCase() == fromUnit.toLowerCase();
    });
    var revUnitModelConvs = revUnitConvs.filter(function (uc) {
      return uc.sensorModel == sensorModel;
    });
    if (revUnitModelConvs.length > 0) {
      return revUnitModelConvs[0];
    }

    var revUniNilModelConvs = revUnitConvs.filter(function (uc) {
      return uc.sensorModel == null;
    });
    if (revUniNilModelConvs.length > 0) {
      return revUniNilModelConvs[0];
    }

    return null;
  }
};