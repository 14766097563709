"use strict";

/* exported Nonin_9560BT */
var Nonin_9560BT = new JS.Class(Bluetooth_Device, {
  initialize: function initialize(device_uuid) {

    var name = "Nonin 9560BT Oximeter";
    var mymedic_id = "NoninOnyx2BTType";

    return this.callSuper(name, device_uuid, mymedic_id, 1);
  }

});