"use strict";

/* exported onLoad handleOpenURL pulseJob syncPulseJob updatePulseJob  */

var fromAppStore = mymobileConfig.baseUrl == "";

var inBrowser = true;
//inBrowser = !!document.URL.match(/^https?:/);

sessionStorage.clear();
localStorage.clear();

var deviceOS = null;
var deviceOSVersion = null;

var LocaleManager = {

  locale: null,
  initialisedLocale: null,

  urlLocale: null,
  nativeLocale: null,

  init: function init() {
    var deferred = $.Deferred();
    var self = this;

    this.urlLocale = localStorage.getItem("urllocale");

    if (inBrowser) {
      this.nativeLocale = navigator.userLanguage || navigator.language;
      deferred.resolve();
    } else {
      navigator.globalization.getLocaleName(function (locale) {
        self.nativeLocale = locale.value;
        deferred.resolve();
      }, function () {
        deferred.resolve();
      });
    }

    return deferred.then(function () {
      self.loadLocale();
    });
  },

  setUrlLocale: function setUrlLocale(urlLocale) {
    this.urlLocale = urlLocale;
    localStorage.setItem("urllocale", urlLocale);
  },

  /* private */
  loadLocale: function loadLocale() {
    // prefer native locale from device over everything else  if supported
    // next use the url locale which originated in TM

    if (mymobileConfig.forceLanguage) {
      this.locale = mymobileConfig.language;
    } else if (this.localeSupported(this.nativeLocale)) {
      this.locale = this.nativeLocale;
    } else if (this.localeSupported(this.urlLocale)) {
      this.locale = this.urlLocale;
    } else {
      this.locale = mymobileConfig.language;
    }

    $("html").attr("lang", this.locale);

    if (this.initialisedLocale != this.locale) {
      this.initialisedLocale = this.locale;
      i18n.init({ lng: this.locale,
        resStore: $.extend(true, I18N_TRANSLATIONS, mymobileConfig.strings),
        fallbackLng: 'en'
      });
    }
  },

  localeSupported: function localeSupported(loc) {
    return $.type(loc) === "string" && (!!I18N_TRANSLATIONS[loc] || !!I18N_TRANSLATIONS[loc.split("-")[0]]);
  }
};

var BaseUrlManager = {
  baseUrl: null,
  fixedBaseUrl: false,

  startup: function startup() {
    if (inBrowser) {
      return;
    }

    // If we were compiled with a baseUrl then use that,
    // don't set it from downloaded config or from an activation URL
    // since activation isn't needed
    if (mymobileConfig.baseUrl && mymobileConfig.baseUrl != "") {
      this.baseUrl = mymobileConfig.baseUrl;
      this.fixedBaseUrl = true;
    }

    // if there is no baseURL get it from localStorage.
    // It gets put into localStorage when the user clicks the Activate link.
    if (this.baseUrl == null) {
      this.baseUrl = localStorage.getItem("baseurl");
    }
  },

  initFromServer: function initFromServer() {
    if (this.activationPending()) {
      var deferred = $.Deferred();
      deferred.resolve();
      return deferred.promise();
    } else {
      return getConfigFromServer();
    }
  },

  activationPending: function activationPending() {
    return !inBrowser && this.baseUrl == null;
  },

  setFromNativeLink: function setFromNativeLink(url) {
    if (!this.fixedBaseUrl) {
      if (url.substr(-1) != '/') {
        url += '/';
      }
      this.baseUrl = url;
      localStorage.setItem("baseurl", url);
    }
  }
};

BaseUrlManager.startup(); // must be before localconfig load

if (!inBrowser) {
  // get config from local storage. If it's not there we'll use
  // the defaults until we can load them
  var localConfig = localStorage.getItem("localConfig");
  if (localConfig) {
    console.log("Getting config locally.....");
    // If we are a new version of mymobile, our built-in config may have settings not in local storage.
    $.extend(true, mymobileConfig, JSON.parse(localConfig));
  }

  var translations = localStorage.getItem("translations");
  if (translations) {
    console.log("Getting translations locally.....");
    // If we are a new version of mymobile:
    //      our built-in translations may have values not in local storage
    //      our built-in translations may have values that are newer than what is in local storage
    // On the other hand, if this isn't a new version of mymobile, the local storage should take precedence
    // since they could be from new translations on the server, however, this is only really possible during developemnt
    // since our policy is to replace translation strings, not modify them.
    $.extend(true, I18N_TRANSLATIONS, JSON.parse(translations));
  }
}

// Wait for device API libraries to load
//
function onLoad() {
  document.addEventListener("deviceready", onDeviceReady, false);
  document.addEventListener("resume", onResume, false);
}

// device APIs are available
//
function onDeviceReady() {

  if (device.platform == "iOS") {
    LocaleManager.init().always(applicationInit);

    //Disable long press to copy link text
    document.body.style.webkitTouchCallout = 'none';
  }

  // Register the event listener
  document.addEventListener("backbutton", onBackKeyDownFunction, false);

  if (!inBrowser) {
    deviceOS = device.platform; //fetch the device operating system
    deviceOSVersion = device.version; //fetch the device OS version)
    $('html').attr('data-platform', deviceOS);
    console.log('OS : ' + deviceOS);
    console.log('ver: ' + deviceOSVersion);
  }

  window.plugin.notification.local.hasPermission(function (granted) {
    if (console) {
      console.log('Permission for notifications has been granted: ' + granted);
    }
  });

  if (deviceOS == 'iOS' && mymobileConfig.healthKitEnabled) {
    Healthkit.checkAvailability().then(Healthkit.requestAuthorization).catch(function () {
      console.error("Healthkit not available on this device");
    });
  }

  if (!inBrowser) {
    BLE_CONTROL.checkBLE();
    BLE_CONTROL.checkIfEnabled();
    BLE_CONTROL.initializeSupportedDeviceMap();
  }
}

function onResume() {
  BLE_CONTROL.checkIfEnabled();
  IdleHandler.checkIfPastIdleTimeout();
}

// Handle the back button
//
var onBackKeyDownFunction = function onBackKeyDownFunction() {
  console.log("Back button pressed!");
};

// Native Only
function handleOpenURL(url) {

  if (Auth.loggedIn()) {
    // already running
    // Ignore activation/change password links
    return;
  }

  parseOpenUrl(url);

  if (APP_STARTED) {
    LocaleManager.init().always(function () {
      setTimeout(function () {
        openPage("register");
      }, 100);
    });
  }
}

function parseOpenUrl(url) {

  url = url.replace("comkanayomymobile://", "");
  var parts = url.split('?');

  if (parts[0] == "register" || parts[0] == "passwordreset") {
    var params = parts[1].split('&').reduce(function (s, c) {
      var t = c.split('=');s[t[0]] = t[1];return s;
    }, {});

    if (params.l) {
      LocaleManager.setUrlLocale(params.l);
    }
    if (params.k) {
      RegisterPage.regKey = params.k;
      RegisterPage.resetPassword = parts[0] == "passwordreset";
    }
    if (params.u) {
      url = decodeURIComponent(params.u);
      BaseUrlManager.setFromNativeLink(url);
    }
    if (params.a) {
      if (params.a == "hcn") {
        RegisterPage.skipRegistration = true;
      }
    }
  }
}

$(function () {
  prepareApplication();
});

function prepareApplication() {

  if (!mymobileConfig.debug) {
    $(".debug-only").hide();
  }

  checkForRegisterUrl();
  if (device.platform != "iOS") {
    LocaleManager.init().always(applicationInit);
  }
}

function checkForRegisterUrl() {
  if (inBrowser) {

    var resetPassword = location.hash.lastIndexOf("#passwordreset", 0) === 0;
    var registration = location.hash.lastIndexOf("#register", 0) === 0;

    if (resetPassword || registration) {
      var regConfig = location.hash.replace("#register", "").replace("#passwordreset", "").split("&");
      RegisterPage.regKey = regConfig[0];

      if (RegisterPage.regKey == "") {
        RegisterPage.regKey = null;
      } else {
        RegisterPage.resetPassword = resetPassword;

        if (regConfig.length > 0) {
          LocaleManager.setUrlLocale(regConfig[1]);
        }
        if (regConfig.length > 1) {
          var authType = regConfig[2];
          if (authType == "hcn") {
            RegisterPage.regKey = null;
            RegisterPage.skipRegistration = true;
          }
        }
      }
    } else {
      RegisterPage.regKey = null;
    }
  }
}

var gLeaveOK = false;

function applicationInit() {
  Date._init_l10n();

  addRipple('[data-rippleria]');

  $('#color-themes ul').on("click", "a", function (e) {
    gSettings.json.theme = e.target.id;
    saveSettings();
    updateTheme();
  });

  if (mymobileConfig.branding.loginHeader) {
    $(".header-logo").show();
    $(".login-header").hide();
  } else {
    $(".login-header").show();
    $(".header-logo").hide();
  }

  if (!mymobileConfig.branding.loginLogo) {
    $(".small-logo").hide();
  }

  $("div[data-role=page]").attr('data-title', $t("app.name"));

  $(window).bind('beforeunload', function () {
    if (!gLeaveOK) return $t("messages.leave_mymobile_warning");
  });

  $(window).bind('onunload', function () {
    Auth.logout();
  });

  $(window).on('orientationchange', function (e) {
    orientationUpdate(e);
  });

  // Go back when you click a header near the back arrow button.
  $("div[data-role=header]").click(function (e) {
    // the h1 element in the header has a 50px left-margin
    // clicks on the h1 don't get here, clicks in the h1 margin do
    var targetData = $(e.target).data();
    if (targetData) {
      // clicks on back button get here, but with the button as the target, ignore since we're already going back.
      if (targetData.role == "header") {
        // only do for headers with a back button
        var backButton = $("a[data-link-type='back-link']", e.target);
        backButton.trigger('click');
      }
    }
  });

  $('#messagePopup').popup({
    transition: "fade",
    shadow: false,
    afteropen: function afteropen(event, ui) {
      setTimeout(function () {
        $('#messagePopup').popup('close');
      }, 2000);
    }
  });

  $('#warningPopup').popup({
    transition: "fade",
    shadow: false,
    afterclose: function afterclose(event, ui) {
      IdleHandler.handleUserEvent();
    }
  });

  $('#confirmPopup').popup({
    transition: "fade",
    shadow: false,
    afterclose: function afterclose(event, ui) {
      IdleHandler.handleUserEvent();
    }
  });

  $("a[onclick]").bind("taphold", function (event) {
    event.preventDefault();
    $(event.target).click();
  });

  IdleHandler.init();
  IdleHandler.start();
  IdleHandler.handleUserEvent(); // prime the idle handler

  $(document).bind('taphold tap mousemove keydown', function (event) {
    IdleHandler.handleUserEvent();
  });

  $(document).bind('contextmenu', function (e) {
    e.preventDefault();
  });

  RegisterPage.setup();
  LoginPage.setup();

  // every minute update schedule things
  var pulseJob = new CronJob('0 */1 * * * *', function () {
    if (Auth.loggedIn()) {
      Interviews.scheduleUpdateFromTimer();

      var currentPage = $.mobile.activePage.attr("id");
      if (currentPage == "main") {
        MainPage.appointmentsMenu();
      } else if (currentPage == "video") {
        VideoPage.displayAppointments();
      }
    }
  }, function () {}, true);

  // every 5 minutes update sync status
  var syncPulseJob = new CronJob('0 */5 * * * *', function () {
    setMainSyncStatus();
  }, function () {}, true);

  // modnight check for app update
  var updatePulseJob = new CronJob('0 0 0 * * *', function () {
    checkForAppUpdates();
  }, function () {}, true);

  $(".hide-during-startup").removeClass("hide-during-startup");

  Store.init();

  DelayedStart.loadedI18n();
}

// Need to apply I18n before JQM opens a page, but after I18n files are loaded.
// Keep us on an empty "initialPage" until both are loaded.
var DelayedStart = {
  i18nLoaded: false,
  firstPageLoaded: false,

  started: function started() {
    return this.i18nLoaded && this.firstPageLoaded;
  },

  loadedI18n: function loadedI18n() {
    this.i18nLoaded = true;
    if (this.started()) {
      openFirstPage();
    }
  },

  loadedFirstPage: function loadedFirstPage(toPage) {
    this.firstPageLoaded = true;
    if (this.started()) {
      openFirstPage();
    }
    return "#initialPage"; // show splash screen
  }
};

var APP_STARTED = false;
function openFirstPage() {
  applyI18nAttrs();
  APP_STARTED = true;
  // leave splash screen for 2 sec
  setTimeout(function () {
    $("body").removeClass("hideOverflow");
    if (BaseUrlManager.activationPending()) openPage('activatePendingPage');else if (RegisterPage.regKey == null || RegisterPage.skipRegistration == true) {
      openPage('login');
    } else {
      openPage('register');
    }
  }, 2000);
}

function applyI18nAttrs(parent) {
  var sel;
  if (parent) sel = $("[data-i18n]", parent);else sel = $("[data-i18n]");

  sel.i18n();
  return parent;
}