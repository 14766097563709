"use strict";

/*exported Nonin_3230 */
var Nonin_3230 = new JS.Class(BLE_Device, {
  initialize: function initialize(device_uuid) {
    var name = "Nonin 3230";
    var model_name = "3230";
    var service_uuid = "46a970e0-0d5f-11e2-8b5e-0002a5d5c51b";
    var measurement_uuids = ["0aad7ea0-0d60-11e2-8e3c-0002a5d5c51b"];

    return this.callSuper(name, model_name, device_uuid, service_uuid, measurement_uuids);
  },

  getAdvertisedName: function getAdvertisedName() {
    return "Nonin3230_" + this.serial;
  },

  serialCheckRequired: function serialCheckRequired() {
    return false; // can use the name
  },

  needsInitialConnect: function needsInitialConnect() {
    return true; // trying to rescan after a failure causes problems without this
  },

  skipReading: function skipReading(reading, readingCount) {

    console.log("Reading Count: " + readingCount);

    if (readingCount < mymobileConfig.Nonin_3230_skip_count) {
      return true;
    } else {
      return false;
    }
  },

  _decode_result: function _decode_result(result, reader) {
    //[10, 52, 22, 2, 197, 2, 93, 98, 0, 72]

    var result_byte_array = new Uint8Array(result);

    var status = this._decode_status_byte(result_byte_array[1].toString(2));
    var battery_voltage = result_byte_array[2] / 10;
    //var sequence_number = kanayo.util.combine_bytes_for_integer([result_byte_array[3], result_byte_array[4]], false);
    //bytes 5,6 reserved for future use.
    var so2 = result_byte_array[7];
    var hr = kanayo.util.combine_bytes_for_integer([result_byte_array[8], result_byte_array[9]], false);

    return {
      valid: !status.searching_for_pulse,
      status: status,
      batteryStatus: battery_voltage,
      date: new Date(),
      readings: [{ main: true,
        measurements: [{ name: "hr", value: "" + hr, unit: "bpm", designations: [] }, { name: "so2", value: "" + so2, unit: "%", designations: [] }]
      }]
    };
  },

  _decode_status_byte: function _decode_status_byte(bit_string) {
    //Make sure byte string is 8 bits by padding MSBs with zeros
    while (bit_string.length < 8) {
      bit_string = "0" + bit_string;
    }

    var low_battery = bit_string[2] == "1" ? true : false;
    var check_finger = bit_string[3] == "1" ? true : false;
    var searching_for_pulse = bit_string[4] == "1" ? true : false;
    var smart_point = bit_string[5] == "1" ? true : false;
    var low_pulse_signal = bit_string[6] == "1" ? true : false;
    var display_sync_indication = bit_string[7] == "1" ? true : false;

    return {
      low_battery: low_battery,
      check_finger: check_finger,
      searching_for_pulse: searching_for_pulse,
      smart_point: smart_point,
      low_pulse_signal: low_pulse_signal,
      display_sync_indication: display_sync_indication
    };
  }
});