'use strict';

var VitalsPage = {

  iconList: { bg: 'bg', wt: 'wt', sbp_dbp: 'bp', hr: 'hr', cmate_rag: "hr", temp: 'temp', so2: 'so2', daily_steps: 'daily_steps', daily_calories: 'daily_calories', daily_distance: 'daily_distance' },
  images: {
    circle_upper: '/images/flot/legend-circle-upper.png',
    circle_lower: '/images/flot/legend-circle-lower.png',
    circle: '/images/flot/legend-circle.png'
  },

  init: function init() {
    $("#vitals-error").hide();
    $("#vitals-loading").show();
    this.getRecentVitals();
  },

  getRecentVitals: function getRecentVitals() {
    var params = { type: "number", full: true, limit_per_measurement: 2, hidden: false, active_only: true, is_measurement_set: true };
    getVitals(JSON.stringify(params), {
      onSuccess: function onSuccess(result) {
        VitalsPage.handleData(result);
      },
      onFailure: function onFailure(result) {
        $("#vitals-loading").hide();
        $(".vitals-item").remove();
        $("#vitals-error").text($t('vitals.not_logged_in')).show();
        Auth.goOffline();
      }
    });
  },

  handleData: function handleData(result) {
    $("#vitals-loading").hide();
    var baseData = this.extractData(asList(result.invocationResult['patient-measurements']['patient-measurement']));
    if (Object.keys(baseData).length == 0) {
      $("#vitals-error").text($t('vitals.no_recent_vitals')).show();
      $(".vitals-item").remove();
    } else {
      $("#vitals-progress").hide();
      $(".vitals-item").remove();

      var index = 0;
      for (var vname in baseData) {
        var vital = baseData[vname];
        vital.range = $t('vitals.range', { returnObjectTrees: true });
        vital.displayValue = formatNumber(vital.value, vital.decimalPlaces);
        if (vital.otherValue != null) {
          vital.displayValue += "/" + formatNumber(vital.otherValue.value, vital.decimalPlaces);
        }
        if (vital.gradeLabel && vital.gradeLabel != "") {
          if (vital.gradeMax) {
            vital.displayValue = vital.displayValue + "/" + vital.gradeMax;
          }
          vital.displayValue = vital.gradeLabel + " (" + vital.displayValue + ")";
        }

        if (vname == "cmate_rag") {
          vital.displayValue = lookupRagStatus(vital.value);
        }

        vital.displayDate = formatPrevDate(vital.date);
        vital.displayTime = vital.daily ? "" : vital.date.format('%X');

        var localNameKey = "vitals.measurement_names." + vital.name;
        var localName = $t(localNameKey);
        if (localName != localNameKey) {
          vital.longName = localName;
        }

        var desigList = [];
        _.each(vital.designations, function (desig) {
          if (desig.hide_designation == "false") {
            desigList.push(desig.label);
          }
        });
        vital.displayDesignations = desigList.join(", ");

        vital.icon = VitalsPage.iconList[vital.name] ? VitalsPage.iconList[vital.name] : 'latest-readings';

        this.computeClinicalInfo(vital);

        if (vital.otherValue != null) {
          this.computeClinicalInfo(vital.otherValue);

          if (vital.otherValue.starColor == "red") vital.starColor = "red";else if (vital.otherValue.starColor == "yellow" && vital.starColor == "green") vital.starColor = "yellow";

          vital.up = vital.up || vital.otherValue.up;
          vital.down = vital.down || vital.otherValue.down;

          this.computeBPTarget(vital, vital.otherValue);
        }

        vital.singleValue = false;
        vital.multiValue = false;
        vital.multiValueList = [];

        if (vital.isMeasurementSet) {
          var measNames = {};
          vital.multiValue = true;

          vital.setMeasurements.forEach(function (setM) {
            if (!measNames[setM.name]) {
              measNames[setM.name] = {
                displayName: setM.label,
                displayValue: kanayo.date.formatHoursMinutes(setM.value),
                displayValue1: kanayo.date.formatHours(setM.value, true),
                displayValue2: kanayo.date.formatMinutes(setM.value)
              };
              vital.multiValueList.push(measNames[setM.name]);
            } else if (measNames[setM.name].prevValue === undefined) {
              measNames[setM.name].prevValue = setM.value;
            }
          });
          vital.multiValueList.unshift({
            displayName: $t("vitals.total_sleep"),
            displayValue: kanayo.date.formatHoursMinutes(vital.value),
            displayValue1: kanayo.date.formatHours(vital.value, true),
            displayValue2: kanayo.date.formatMinutes(vital.value)
          });
        } else {
          vital.singleValue = true;
        }

        //Graph Data
        vital.graphIndex = index;
        vital.dateRange = "week";
        vital.sensorTypeId = "none";

        //Send pair of measurement Ids for sbp_ddb
        if (vital.measurementIds != undefined) {
          vital.measurementId = "";
          vital.measurementIds.forEach(function (element, index, array) {
            vital.measurementId += element + ",";
          });
          vital.measurementId = vital.measurementId.slice(0, -1);
        }

        $('#vitals-menu').append($(ich.t_vitalsDetail(vital, true))).trigger("create");
        index += 1;
      }
      $(".vital-graph-loading > a").html($t("progress.loading"));
      $('#vitals-menu').listview("refresh");
    }
  },

  formatPair: function formatPair(val, otherVal, decs) {
    return (val == null ? "---" : formatNumber(val, decs)) + "/" + (otherVal == null ? "---" : formatNumber(otherVal, decs));
  },

  computeBPTarget: function computeBPTarget(vital, other) {
    if (!vital.showLimits) {
      vital.targetText = null;
    } else if (vital.targetType == "both" || other.targetType == "both" || vital.targetType == "high" && other.targetType == "low" || vital.targetType == "low" && other.targetType == "high") {
      vital.targetText = $t("vitals.target_range").replace("{{low}}", this.formatPair(vital.lowLimit, other.lowLimit, vital.decimalPlaces)).replace("{{high}}", this.formatPair(vital.highLimit, other.highLimit, vital.decimalPlaces));
    } else if ((vital.targetType == "high" || other.targetType == "high") && vital.targetType != "low" && other.targetType != "low") {
      vital.targetText = $t("vitals.target_high") + this.formatPair(vital.highLimit, other.highLimit, vital.decimalPlaces);
    } else if ((vital.targetType == "low" || other.targetType == "low") && vital.targetType != "high" && other.targetType != "high") {
      vital.targetText = $t("vitals.target_low") + this.formatPair(vital.lowLimit, other.lowLimit, vital.decimalPlaces);
    } else if (vital.targetType == "target" || other.targetType == "target") {
      vital.targetText = $t("vitals.target") + this.formatPair(vital.target, other.target, vital.decimalPlaces);
    } else {
      vital.targetText = null;
    }
  },

  computeClinicalInfo: function computeClinicalInfo(vital) {
    if (!vital.showLimits) {
      vital.targetType = "none";
      vital.targetText = null;
    } else if (vital.highLimit != null && vital.lowLimit != null) {
      vital.targetType = "both";
      vital.targetText = $t("vitals.target_range").replace("{{low}}", formatNumber(vital.lowLimit, vital.decimalPlaces)).replace("{{high}}", formatNumber(vital.highLimit, vital.decimalPlaces));
    } else if (vital.highLimit != null) {
      vital.targetType = "high";
      vital.targetText = $t("vitals.target_high") + formatNumber(vital.highLimit, vital.decimalPlaces);
    } else if (vital.lowLimit != null) {
      vital.targetType = "low";
      vital.targetText = $t("vitals.target_low") + formatNumber(vital.lowLimit, vital.decimalPlaces);
    } else if (vital.target != null) {
      vital.targetType = "target";
      vital.targetText = $t("vitals.target") + formatNumber(vital.target, vital.decimalPlaces);
    } else {
      vital.targetType = "none";
      vital.targetText = null;
    }

    if (vital.targetType == "none") {
      vital.starColor = "none";
    } else {
      vital.starColor = vital.alertLevel == "high" ? "red" : vital.alertLevel == "marginal" ? "yellow" : "green";
    }

    vital.up = vital.prevValue && vital.value > vital.prevValue;
    vital.down = vital.prevValue && vital.value < vital.prevValue;
  },

  extractData: function extractData(vitalsList) {

    var baseData = {};
    var bpData = {};
    var setData = {};
    var allData = [];
    _.each(vitalsList, function (elem) {
      var datum = {
        name: elem.name,
        label: elem.label,
        patientId: parseInt(elem["patient-id"]),
        measurementId: parseInt(elem["measurement-id"]),
        isMeasurementSet: elem["is-measurement-set"] == "true",
        alertLevel: elem['alert-level'],
        longName: elem.description == "" || elem.description == null ? elem.label : elem.description,
        date: moment(elem.date).toDate(),
        value: parseFloat(elem.value),
        gradeLabel: elem['grade-label'],
        gradeMax: elem['grade-max'],
        otherValue: null,
        daily: elem.daily == "true",
        units: elem.units,
        decimalPlaces: parseInt(elem["decimal-places"]),
        unitName: elem.unit_name,
        designations: asList(elem.designation),
        target: elem.limits && elem.limits.target ? parseFloat(elem.limits.target) : null,
        highLimit: elem.limits && elem.limits['alert-high'] ? parseFloat(elem.limits['alert-high']) : null,
        lowLimit: elem.limits && elem.limits['alert-low'] ? parseFloat(elem.limits['alert-low']) : null,
        showLimits: elem.limits && elem.limits['patient-visible-limits'] == "true"
      };
      if (elem.name == 'sbp' || elem.name == 'dbp') {
        if (!bpData[elem['reading-id']]) {
          bpData[elem['reading-id']] = {};
        }
        bpData[elem['reading-id']][elem.name] = datum;
      } else if (elem['measurement-set-id'] != null && elem['measurement-set-id'] != '') {
        var setId = parseInt(elem['measurement-set-id']);
        if (setData[setId] == null) {
          setData[setId] = [];
        }
        setData[setId].push(datum);
      } else {
        allData.push(datum);
      }

      if (elem.name == 'cmate_rag') {
        if (parseInt(datum.value) == 3) {
          datum.alertLevel = "red";
        } else if (parseInt(datum.value) == 2) {
          datum.alertLevel = "amber";
        } else {
          datum.alertLevel = "green";
        }
      }
    });

    for (var readingId in bpData) {
      var rData = bpData[readingId];
      if (rData.sbp && rData.dbp) {
        var measurementIds = [rData.sbp.measurementId, rData.dbp.measurementId];
        allData.push($.extend({}, rData.sbp, { name: 'sbp_dbp', longName: $t("vitals.measurement_names.sbp_dbp"), measurementIds: measurementIds, otherValue: rData.dbp }));
      }
    }

    allData = allData.sort(function (a, b) {
      return b.date - a.date;
    });

    _.each(allData, function (datum) {
      if (!baseData[datum.name]) {
        baseData[datum.name] = datum;
        baseData[datum.name].measurements = [];
      } else if (baseData[datum.name].prevValue === undefined) {
        baseData[datum.name].prevValue = datum.value;
        if (datum.otherValue != null) baseData[datum.name].otherValue.prevValue = datum.otherValue.value;
      }
      baseData[datum.name].measurements.push({ date: datum.date, value: datum.value });

      if (datum.isMeasurementSet && baseData[datum.name].set_measurements == null) {
        baseData[datum.name].setMeasurements = setData[datum.measurementId];
      }
    });

    return baseData;
  },

  revealGraph: function revealGraph(elem) {
    var graphContainer = $(elem).find('.vital-graph-container');
    if (graphContainer.is(":visible")) {
      return true;
    }
    Store.clearAllGraphData();
    graphContainer.slideDown({ complete: setTimeout(function () {
        loadGraph($(graphContainer).find('.vital-graph'));
        $(elem).find('.vital-graph-show-hide > a.ui-btn.open').hide();
        $(elem).find('.vital-graph-show-hide > a.ui-btn.close').show();
      }, 500) });
  },

  hideGraph: function hideGraph(elem) {
    var graphContainer = $(elem).closest('.vitals-item').find('.vital-graph-container');
    if (!graphContainer.is(":visible")) {
      return true;
    }

    GraphSupport.clearGraphHover();

    graphContainer.slideUp({
      complete: function complete() {
        $(graphContainer).find("canvas.flot-base").remove();
        $(graphContainer).find("canvas.flot-overlay").remove();
        $(graphContainer).find("div.flot-text").remove();
        $(elem).parent().find('a.ui-btn.open').show();
        $(elem).parent().find('a.ui-btn.close').hide();
      }
    });
  },

  reset: function reset() {
    VitalsPage.hideGraphs();
    $('li.vitals-item').remove();
  },

  hideGraphs: function hideGraphs() {
    $('.vital-graph-legend').hide();
    $('.vital-graph-container').remove();

    GraphSupport.clearGraphHover();
  },

  changeRange: function changeRange(elem) {
    var checked_elem = $(elem);
    var range = checked_elem.val();

    var graph = $(elem).parents('.vital-graph-container').find('.vital-graph');
    $(graph).attr('data-date-range', range);

    Store.clearAllGraphData();
    loadGraph(graph, range);
    return true;
  }
};

var StatsController = {

  currentStatsDateRange: '7',

  drawStats: function drawStats(ago) {
    $("#stats-content").hide();
    $("#stats-error").hide();
    $("#stats-loading").show();

    var params = { name: 'bg', full: true };

    if (ago == null) {
      ago = this.currentStatsDateRange;
    }
    this.currentStatsDateRange = ago;

    if (ago != 'all') {
      var start = moment().subtract('days', ago);
      params.start_date = start.format("YYYY-MM-DDTHH:mm:ss");
    }

    getVitals(JSON.stringify(params), {
      onSuccess: function onSuccess(result) {
        StatsController.handleData(result);
      },
      onFailure: function onFailure(result) {
        $("#stats-loading").hide();
        $("#stats-error").text($t('stats.not_logged_in')).show();
      }
    });
  },

  handleData: function handleData(result) {
    $("#stats-loading").hide();
    var baseData = this.extractData(asList(result.invocationResult['patient-measurements']['patient-measurement']));
    if (baseData.length == 0) {
      $("#stats-error").text($t('stats.no_data')).show();
    } else {
      $("#stats-content").show();

      var maxBg = _.reduce(baseData, function (max, bgData) {
        return bgData.value > max ? bgData.value : max;
      }, 0);
      $('#stats-max').text(formatNumber(maxBg, 1));

      this.drawAverage(this.getHourPoints(baseData));
      this.drawLimitStats(this.getLimitCounts(baseData));
      this.drawBG(this.getMealPoints(baseData));
    }
  },

  extractData: function extractData(vitalsList) {
    var baseData = [];
    _.each(vitalsList, function (elem) {
      var datum = {
        date: moment(elem.date),
        value: parseFloat(elem.value),
        meal: null,
        exercise: null
      };

      if (elem.units.toLowerCase() == 'mg/dl') {
        datum.value = datum.value / 18.02;
      }

      var desigs = asList(elem.designation);
      _.each(desigs, function (desig) {
        if (desig['designation-type-name'] == 'diabetes_bg') datum.meal = desig.name;else if (desig['designation-type-name'] == 'exercise') datum.exercise = desig.name;
      });
      baseData.push(datum);
    });

    return baseData;
  },

  getMealPoints: function getMealPoints(baseData) {
    var mealData = {};
    _.each(baseData, function (bgData) {
      if (bgData.meal != null) {
        if (mealData[bgData.meal] == null) {
          mealData[bgData.meal] = [];
        }
        mealData[bgData.meal].push(bgData.value);
      }
    });
    var mealPoints = [];
    var mealNameIndexes = {
      'bedtime': 1,
      'after_dinner': 2,
      'before_dinner': 3,
      'after_lunch': 4,
      'before_lunch': 5,
      'after_breakfast': 6,
      'fasting': 7 };
    for (var mealName in mealData) {
      var sum = 0;
      _.each(mealData[mealName], function (bgVal) {
        sum += bgVal;
      });
      var avg = sum / mealData[mealName].length;
      mealPoints.push([avg, mealNameIndexes[mealName]]);
    }

    return mealPoints;
  },

  getHourPoints: function getHourPoints(baseData) {
    var hourData = {};
    _.each(baseData, function (bgData) {
      var hour = bgData.date._a[3];
      if (hourData[hour] == null) {
        hourData[hour] = [];
      }
      hourData[hour].push(bgData.value);
    });
    var hourPoints = [];
    for (var hour in hourData) {
      var sum = 0;
      _.each(hourData[hour], function (bgVal) {
        sum += bgVal;
      });
      var avg = sum / hourData[hour].length;
      hourPoints.push([hour, avg]);
    }
    return hourPoints;
  },

  getLimitCounts: function getLimitCounts(baseData) {
    var limits = {
      veryHigh: 10,
      high: 6,
      normal: null,
      low: 4
    };
    var limitCounts = {
      veryHigh: 0,
      high: 0,
      normal: 0,
      low: 0
    };
    _.each(baseData, function (bgData) {
      if (bgData.value > limits.veryHigh) limitCounts.veryHigh += 1;else if (bgData.value > limits.high) limitCounts.high += 1;else if (bgData.value < limits.low) limitCounts.low += 1;else limitCounts.normal += 1;
    });

    return limitCounts;
  },

  drawAverage: function drawAverage(hourPoints) {
    $.plot($("#stats-avg"), [hourPoints], {
      series: {
        lines: { show: true },
        points: { show: true }
      },
      yaxis: {
        min: 0,
        max: 20
      },
      xaxis: {
        min: 0,
        max: 23,
        ticks: [[3, $t('stats.time_labels.3')], [6, $t('stats.time_labels.6')], [9, $t('stats.time_labels.9')], [12, $t('stats.time_labels.12')], [15, $t('stats.time_labels.15')], [18, $t('stats.time_labels.18')], [21, $t('stats.time_labels.21')]]
      }
    });
  },

  drawLimitStats: function drawLimitStats(limitCounts) {
    var d2 = [{ label: $t('stats.limit_labels.very_high'), data: limitCounts.veryHigh }, { label: $t('stats.limit_labels.high'), data: limitCounts.high }, { label: $t('stats.limit_labels.normal'), data: limitCounts.normal }, { label: $t('stats.limit_labels.low'), data: limitCounts.low }];

    $.plot($("#stats-pie"), d2, {
      series: {
        pie: {
          show: true,
          radius: 1,
          label: {
            show: true,
            radius: 3 / 4,
            formatter: function formatter(label, series) {
              return '<div style="font-size:8pt;text-align:center;padding:2px;color:white; font-weight:normal;">' + Math.round(series.percent) + '%</div>';
            }
          }
        }
      },
      legend: {
        show: true,
        backgroundColor: "#eee" // specific to jqm, theme c
      },
      colors: ['rgb(203,75,75)', 'rgb(237,194,64)', 'rgb(77,167,77)', 'rgb(175,216,248)']
    });
  },

  drawBG: function drawBG(mealPoints) {
    $.plot($("#stats-bar"), [mealPoints], {
      series: {
        bars: { show: true, horizontal: true, align: 'center' }
      },
      xaxis: {
        min: 0,
        max: 20
      },
      yaxis: {
        ticks: [[0, ''], [1, $t('stats.meal_labels.night')], [2, $t('stats.meal_labels.after_dinner')], [3, $t('stats.meal_labels.before_dinner')], [4, $t('stats.meal_labels.after_lunch')], [5, $t('stats.meal_labels.before_lunch')], [6, $t('stats.meal_labels.after_breakfast')], [7, $t('stats.meal_labels.before_breakfast')]]
      }
    });
  }
};